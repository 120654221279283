@import'variables';


ul#azlist{
  display: flex;
  justify-content: space-between;
  li{
    background: $bgblack;
    text-align: center;
    margin: .25rem;
    flex: 1;
    &:hover{
      background: $lightblack;
    }
    a, span {
      display: block;
      color:white;
      font-size: 1.2rem;
      line-height: 2.6rem;
    }
  }
  .unused{
    background: $bordercolor;
    a{cursor:default}
    &:hover{background: $bordercolor;}
  }
  .active{
    background: $gradient;
  }
}

ul#listresult{
  -webkit-columns: 5;
  columns: 5;
  li{

    a{
      line-height: 1.5rem;
    }
  }
}
