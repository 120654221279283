@import 'variables';

.activeaudioplayer {
  margin-bottom: 6rem !important;
}

/*AUDIOPLAYER*/
#p-wrapper {
  .notice {
    background: $red;
    position: absolute;
    z-index: 10;
    top: -21px;
    width: 100%;
    padding: 3px 0;
    p {
      color: white;
      font-size: 12px;
      margin: 0;
      text-align: center;
    }
  }
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 70px;
}

#p {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0 2rem;
  font-size: 0.8rem;
  overflow: hidden;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);

  #cover {
    background-size: cover;
    background-position: center center;
    filter: blur(15px);
    height: 150%;
    width: 100%;
    transform: scale(1.2);
    opacity: 0.8;
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
  }
  // background-blend-mode:soft-light;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    content: '';
  }

  h4 {
    color: white;
    display: inline;
    span {
      color: white;
    }
  }

  div {
    position: relative;
    z-index: 4;
    display: inline-block;
    line-height: 70px;
  }

  img {
    width: 1rem;
    // float:left;
  }

  #pl img {
    padding-right: 1rem;
  }

  #pc {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    line-height: 65px;
    .pp {
      width: 2rem;
      margin: 0 1rem;
    }
  }

  #progress-wp {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    height: 0.8rem;
    #progress {
      background: $blue;
      height: 0.25rem;
      margin: 0;
    }
  }

  #pr {
    float: right;
    display: flex;
    time {
      span {
        min-width: 40px;
        display: inline-block;
        text-align: center;
      }
    }
  }

  .icons {
    margin-left: 20px;
    display: flex;
  }

  .btn-icon {
    background: transparent;
    border: 0;
    outline: 0;
    color: white;
    cursor: pointer;
  }
}

@media (max-width: 700px) {
  #p h4 span,
  #pl img {
    display: none;
  }
}
#vwrap {
  margin: 0 1rem 0 0.5rem;
}

#rangebg {
  background: grey;
  height: 7px;
  margin: 0;
  width: 70px;
  display: inline-block;
  border-radius: 1rem;
  cursor: pointer;
  #activebg {
    background: white;
    height: 7px;
    margin: 0;
    border-radius: 1rem;
  }
}
