@import'variables';

/* BUTTONS */

.rc-slider-rail {
  overflow: visible;
  background: $bordercolor;
  border-radius: 3px;
  box-shadow: inset 0 0 0 2px rgba(0,0,0,.05);
  height: 6px;
  border: 1px solid #d8d8d8;
}

.rc-slider-track {
  background: $gradient;
  position: absolute;
  height: 6px;
}

.rc-slider-handle {
  border: none;
  border-radius: 100%;
  height: 24px;
  outline: none;
  z-index: 2;
  width: 24px;
  margin-top: -9px;

  &.rc-slider-handle-1 {
    background: $green;
  }
  &.rc-slider-handle-2 {
    background: $blue;
  }
}


