@import'variables';

/* Folders */


sidebar#folders{
  position: fixed;
  background: $bgblack;
  bottom:0;
  height: calc(100vh - 71px);
  width: 300px;
  z-index: 2;
  left:-300px;
  transition: 300ms;

  header{
    margin:2rem 2rem 1rem;

    svg{
      fill:$bg;
      vertical-align: sub;
      width: 1.3rem;
      height: 1.3rem;
    }
    h5{
      color:$bg;
      display: inline;
      padding-left: 1rem;
    }
    img{
      width: 1rem;
      float: right;
    }
    #fline{
      border-bottom: 1px solid $bg;
      height: 1px;
      margin:1rem 0 0 0;
    }

  }

  ul{
    margin:0 2rem;

    li{
      list-style: none;
      color:white;
      font-size: .8rem;
      line-height: 1.4rem;
      -webkit-font-smoothing:antialiased;
      img{
        height: 1rem;
        padding-right: .5rem;
      }
      a{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      p{
        margin:0;
        width: 200px;
        color:$bg;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor:pointer;
        &:hover{
          text-decoration: underline;
        }
      }
      span{
        background: $black;
        border-radius: $radius;
        text-align: center;
        color:$bg;
        width: 20px;
        height: 20px;
        font-size: .6rem;
        margin: .2rem 0;

      }
    }
  }

}

#folders.active{
    left:0!important
  }


.note{
  background: none;
  border:none;
  width: 100%;
  resize: none;
  font-size: .8rem;

}

#sharedwith{
  font-size: .8rem;
  margin: .5rem 0 2rem 0;

}

.folderview{

  h6{
    margin-bottom: .8rem;
  }


  .folderh{
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
    align-items: center;

    .note{
      margin-bottom: 2rem;
    }


    h3{
      margin: 0;
    }

    span{
      font-size: .8rem;
      color:$bgblack;
      img{
        height: 1rem;
        padding-right: .4rem;
      }
      p{
        display: inherit;
      }


      a{
        display: inline-block;
        padding-right: 1.5rem;
        &:hover{
          opacity: .7;
        }
      }
    }

  }
  .btninvite{
    margin-left: 0;
  }
}

#folderspacer{
  height: 30px;
}
