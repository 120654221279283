@charset "UTF-8";
html {
  box-sizing: border-box;
  height: 100vh; }

body {
  background: #f5f5f5;
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  margin: 0;
  height: 100vh; }

a:link, a:visited, a:hover, a:active {
  color: #151515;
  text-decoration: none; }

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  font-weight: 300;
  color: #151515; }

h3, h4 {
  color: #151515; }

img {
  vertical-align: middle; }

ul {
  margin: 0;
  padding: 0; }

li {
  list-style: none; }

textarea:focus, input:focus {
  outline: none; }

input {
  box-sizing: border-box; }

main {
  margin-top: 71px !important; }

main.login {
  height: 90vh;
  display: flex;
  justify-content: center;
  padding-top: 10vh;
  margin-top: 0 !important; }

hr {
  margin: 3rem 0;
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

p {
  font-weight: 300; }

#searched {
  background: #f5f5f5; }
  #searched #searchgroup {
    margin-top: 0;
    background-color: #ddd;
    padding: 3rem 0;
    border-bottom: 1px solid #ccc; }

.trackandartist ul {
  margin-bottom: 15px; }

#searchgroup {
  text-align: center;
  -webkit-font-smoothing: antialiased;
  padding-top: 20vh; }
  #searchgroup .trackandartist ul {
    margin-bottom: 15px; }
  #searchgroup ul {
    color: #777; }
    #searchgroup ul .active {
      font-weight: 700;
      color: #26272b; }
    #searchgroup ul li {
      display: inline-block;
      padding: 0 0.1rem;
      color: #777; }
      #searchgroup ul li:hover {
        text-decoration: underline; }
  #searchgroup #bigsrc {
    background: #f5f5f5 url("../img/inputsearch.svg") no-repeat 27.3rem;
    background-size: 2rem 2rem;
    width: 30rem;
    border: 1px solid #ccc;
    border-radius: 0.3rem;
    padding: 1rem 1.5rem;
    font-size: 1rem; }

@media (max-width: 700px) {
  #searchgroup #bigsrc {
    width: calc(100% - 4rem);
    margin: 0 2rem; } }

#logingroup {
  text-align: center;
  width: 20rem;
  -webkit-font-smoothing: antialiased; }
  #logingroup #llogo {
    width: 6rem;
    margin-bottom: 2rem; }
  #logingroup input {
    background-color: white;
    width: 20rem;
    margin: 0 0 0.5rem;
    padding: 1rem 1.5rem;
    font-size: 0.8rem;
    border: 1px solid #ccc; }
  #logingroup p {
    font-size: 1rem;
    color: #777;
    padding: 0.5rem 0; }

#content {
  margin: 0 2rem;
  padding: 1rem 0; }
  #content header {
    display: flex;
    align-items: baseline;
    margin: 3rem 0 1rem; }
    #content header img {
      margin-right: 0;
      width: 1.5rem;
      opacity: 0.5; }
      #content header img:hover {
        opacity: 0.7; }
    #content header .inactive {
      cursor: default;
      opacity: 0.15; }
      #content header .inactive:hover {
        opacity: 0.15; }

@media (min-width: 1300px) {
  #content {
    margin: 1rem 2vw; } }

@media (min-width: 1600px) {
  #content {
    margin: 1rem 5vw; } }

@media (min-width: 2000px) {
  #content {
    margin: 1rem 7vw; } }

.toppadding {
  padding-top: 2rem; }

#noresult {
  text-align: center;
  padding-top: 20vh;
  color: #777;
  font-size: 2rem; }

p#breadcrumb {
  -webkit-font-smoothing: antialiased;
  font-size: 0.8rem;
  text-transform: uppercase; }
  p#breadcrumb .active {
    font-weight: 700;
    color: #cd2134; }
  p#breadcrumb a:hover {
    opacity: 0.7; }

#cheader {
  position: relative;
  display: flex;
  margin-top: 71px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center calc(0% + 20%); }
  #cheader #color {
    background: linear-gradient(to right, rgba(242, 79, 55, 0.9) 0%, rgba(205, 33, 52, 0.9) 100%);
    box-shadow: inset 0px 0px 21px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2; }
  #cheader h1 {
    color: white;
    z-index: 3;
    font-size: 3rem;
    text-transform: uppercase; }

#e404 {
  background: url("../img/404.jpg") no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center; }
  #e404 h5,
  #e404 h6,
  #e404 p {
    color: white;
    padding: 0.3rem; }
  #e404 h5 {
    font-size: 2rem; }
  #e404 h6 {
    font-size: 1.5rem;
    margin: 0 3rem; }
  #e404 p {
    margin: 0 3rem; }

#emptystate {
  width: 100%;
  margin: 20vh 0 0 0;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ddd; }
  #emptystate svg {
    height: 7rem;
    width: 7rem;
    fill: #ddd; }

.contentpush {
  margin-top: 8rem !important; }

.betainfo {
  background: #ddd;
  padding: 1rem;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  line-height: 1.3rem; }
  .betainfo p,
  .betainfo i,
  .betainfo h4 {
    color: #777; }
  .betainfo p {
    margin: 0; }
  .betainfo h4 {
    margin: 0 0 0.5rem 0; }
  .betainfo span {
    display: flex;
    justify-content: space-between; }
    .betainfo span img {
      width: 12px;
      height: 12px; }
      .betainfo span img:hover {
        opacity: 0.7; }

.artistpage {
  margin-top: 1rem !important; }

#arhead {
  height: 450px;
  background: black;
  overflow: hidden;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center; }
  #arhead figure {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    background-position: 25% center;
    filter: blur(10px);
    transform: scale(1.2);
    opacity: 0.8; }
    #arhead figure.empty {
      background: linear-gradient(45deg, #27bea5 0%, #3684e6 100%) !important; }
  #arhead .c {
    position: absolute;
    text-align: center;
    padding: 0 5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white; }
    #arhead .c h1 {
      font-size: 4rem; }
    #arhead .c h3 {
      display: block;
      color: white;
      font-size: inherit;
      margin-bottom: 5px; }
    #arhead .c ul.c-artists li {
      display: inline-block;
      padding: 0 5px; }
      #arhead .c ul.c-artists li:after {
        margin-left: 10px;
        content: '|'; }
      #arhead .c ul.c-artists li:last-child:after {
        content: ''; }
      #arhead .c ul.c-artists li a {
        color: white;
        line-height: 1.5rem; }
        #arhead .c ul.c-artists li a:hover {
          text-decoration: underline;
          text-underline-position: under; }

.vert {
  flex-direction: column; }
  .vert p {
    margin-top: 0; }

/* MODAL */
#modal {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center; }
  #modal svg {
    position: absolute;
    top: 3rem;
    right: 3rem;
    height: 3rem;
    width: 3rem;
    cursor: pointer; }
  #modal #wrap {
    background: #f5f5f5;
    width: 500px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
    #modal #wrap header {
      width: 100%;
      padding: 1rem;
      border-bottom: 1px solid #ddd;
      box-sizing: border-box;
      background: white; }
    #modal #wrap .content {
      padding: 0 1rem 1rem 1rem;
      max-height: 700px;
      overflow-y: auto; }
    #modal #wrap #footer {
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: 700;
      cursor: pointer; }
    #modal #wrap .blue {
      background: #3684e6; }
      #modal #wrap .blue:hover {
        background: #63a0ec; }
    #modal #wrap .green {
      background: #27bea5; }
      #modal #wrap .green:hover {
        background: #40d8bf; }
  #modal .userbasic, #modal article {
    margin: 0; }
  #modal p.submeta {
    text-align: center;
    color: #555; }

.activeaudioplayer {
  margin-bottom: 6rem !important; }

/*AUDIOPLAYER*/
#p-wrapper {
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 70px; }
  #p-wrapper .notice {
    background: #cd2134;
    position: absolute;
    z-index: 10;
    top: -21px;
    width: 100%;
    padding: 3px 0; }
    #p-wrapper .notice p {
      color: white;
      font-size: 12px;
      margin: 0;
      text-align: center; }

#p {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0 2rem;
  font-size: 0.8rem;
  overflow: hidden;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3); }
  #p #cover {
    background-size: cover;
    background-position: center center;
    filter: blur(15px);
    height: 150%;
    width: 100%;
    transform: scale(1.2);
    opacity: 0.8;
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0; }
  #p:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    content: ''; }
  #p h4 {
    color: white;
    display: inline; }
    #p h4 span {
      color: white; }
  #p div {
    position: relative;
    z-index: 4;
    display: inline-block;
    line-height: 70px; }
  #p img {
    width: 1rem; }
  #p #pl img {
    padding-right: 1rem; }
  #p #pc {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    line-height: 65px; }
    #p #pc .pp {
      width: 2rem;
      margin: 0 1rem; }
  #p #progress-wp {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    height: 0.8rem; }
    #p #progress-wp #progress {
      background: #3684e6;
      height: 0.25rem;
      margin: 0; }
  #p #pr {
    float: right;
    display: flex; }
    #p #pr time span {
      min-width: 40px;
      display: inline-block;
      text-align: center; }
  #p .icons {
    margin-left: 20px;
    display: flex; }
  #p .btn-icon {
    background: transparent;
    border: 0;
    outline: 0;
    color: white;
    cursor: pointer; }

@media (max-width: 700px) {
  #p h4 span,
  #pl img {
    display: none; } }

#vwrap {
  margin: 0 1rem 0 0.5rem; }

#rangebg {
  background: grey;
  height: 7px;
  margin: 0;
  width: 70px;
  display: inline-block;
  border-radius: 1rem;
  cursor: pointer; }
  #rangebg #activebg {
    background: white;
    height: 7px;
    margin: 0;
    border-radius: 1rem; }

/* Navigation */
nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  display: flex;
  justify-content: space-between;
  height: 70px;
  border-bottom: 2px solid #ccc;
  z-index: 4; }
  nav ul {
    display: flex;
    align-items: center; }
    nav ul li {
      padding: 0 .5rem; }
      nav ul li.nav-dropdown {
        display: flex;
        align-items: center;
        height: 74px;
        position: relative; }
        nav ul li.nav-dropdown svg {
          width: 1rem;
          padding-left: .5rem; }
        nav ul li.nav-dropdown:hover #menu {
          visibility: visible; }
        nav ul li.nav-dropdown #menu {
          top: 74px;
          left: 50%;
          margin-left: -4.5rem;
          background: #fff;
          position: absolute;
          z-index: 3;
          display: block;
          visibility: hidden;
          margin-top: -2px;
          background-color: #fff !important; }
          nav ul li.nav-dropdown #menu li {
            min-width: 9rem; }
      nav ul li a h5 {
        padding: .6rem .9rem;
        background: inherit; }
      nav ul li a.active h5, nav ul li a:hover h5 {
        padding: .6rem .9rem;
        color: white;
        border-radius: 0.3rem;
        background: linear-gradient(45deg, #27bea5 0%, #3684e6 100%); }
    nav ul li#logomargin {
      margin-right: 3rem;
      overflow: hidden; }
      nav ul li#logomargin figure {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        background: linear-gradient(45deg, #1ed688 0%, #3681e9 100%);
        width: 6rem;
        height: 71px;
        margin: 0; }
      nav ul li#logomargin img {
        position: relative;
        z-index: 3; }
  nav img {
    height: 35px;
    padding: calc(35px/2) 0 1rem 1rem; }
  nav #partnerlogo {
    height: 25px;
    padding: 25px 0; }
  nav #ftoggle svg, nav #ctoggle svg {
    width: 1.3rem;
    height: 1.3rem; }
  nav svg {
    fill: #151515;
    cursor: pointer; }
    nav svg:hover {
      fill: #26272b; }
  nav li:hover #user, nav li:hover #menu {
    display: block; }
  nav ul#menu {
    left: 24rem;
    background: white;
    position: absolute;
    z-index: 3;
    display: none;
    margin-top: -2px;
    background-color: white !important; }
    nav ul#menu li {
      padding: .3rem 1rem;
      align-content: center;
      border-bottom: 1px solid #ddd;
      font-size: .8rem;
      width: 8rem;
      border-right: 1px solid #ddd;
      border-left: 1px solid #ddd;
      box-sizing: border-box;
      border-left: 5px solid transparent; }
      nav ul#menu li a {
        display: flex;
        align-items: center; }
      nav ul#menu li:hover {
        border-left-color: #3684e6; }
  nav ul#user {
    background: white;
    position: absolute;
    z-index: 3;
    right: 0;
    display: none;
    margin-top: -3px; }
    nav ul#user li {
      padding: .3rem 1rem;
      align-content: center;
      border-bottom: 1px solid #ddd;
      font-size: .8rem;
      width: 12rem;
      border-right: 1px solid #ddd;
      border-left: 1px solid #ddd;
      box-sizing: border-box; }
      nav ul#user li a {
        display: flex;
        align-items: center; }
      nav ul#user li:hover {
        border-left: 5px solid #3684e6; }
      nav ul#user li svg {
        width: 1rem;
        padding-right: .5rem; }
      nav ul#user li:last-child {
        border-bottom: none;
        background-color: #26272b; }
        nav ul#user li:last-child svg {
          fill: white; }
        nav ul#user li:last-child p {
          color: white; }

#dd {
  display: flex;
  align-items: center;
  height: 74px; }
  #dd svg {
    width: 1rem;
    padding-left: .5rem; }

/* Track page */
#trackinfo,
.tracklistinfo {
  display: flex;
  margin-top: 4rem; }
  #trackinfo #artwork,
  .tracklistinfo #artwork {
    width: 300px;
    height: 300px;
    opacity: 0.8; }
  #trackinfo section,
  .tracklistinfo section {
    display: flex;
    flex-direction: column; }
    #trackinfo section .tleft,
    .tracklistinfo section .tleft {
      margin: 0;
      width: 300px;
      height: 300px;
      position: relative; }
      #trackinfo section .tleft.overlay,
      .tracklistinfo section .tleft.overlay {
        background: black; }
      #trackinfo section .tleft #flag,
      .tracklistinfo section .tleft #flag {
        width: 100%;
        max-width: 250px;
        max-height: 250px; }
      #trackinfo section .tleft a .play,
      .tracklistinfo section .tleft a .play {
        position: absolute;
        top: 45%;
        left: 50%;
        width: 50px;
        transform: translate(-50%, 0); }
        #trackinfo section .tleft a .play:hover,
        .tracklistinfo section .tleft a .play:hover {
          opacity: 0.7; }
      #trackinfo section .tleft figure,
      .tracklistinfo section .tleft figure {
        margin: 0; }
      #trackinfo section .tleft figure.play,
      #trackinfo section .tleft figure.pause,
      .tracklistinfo section .tleft figure.play,
      .tracklistinfo section .tleft figure.pause {
        content: url(../img/play.svg);
        opacity: 0.9;
        width: 5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 9;
        transform: translate(-50%, -50%);
        cursor: pointer; }
        #trackinfo section .tleft figure.play:hover,
        #trackinfo section .tleft figure.pause:hover,
        .tracklistinfo section .tleft figure.play:hover,
        .tracklistinfo section .tleft figure.pause:hover {
          opacity: 1; }
      #trackinfo section .tleft figure.pause,
      .tracklistinfo section .tleft figure.pause {
        content: url(../img/pause.svg); }
    #trackinfo section button,
    .tracklistinfo section button {
      width: 300px;
      margin-top: 1rem; }

.tracklistinfo section {
  flex-direction: row;
  border: none; }

#trackmeta {
  margin-left: 3rem; }
  #trackmeta h1 {
    margin-bottom: 0.5rem; }
  #trackmeta h2 {
    margin: 0;
    opacity: 0.5; }
    #trackmeta h2:hover {
      opacity: 0.3; }
  #trackmeta p {
    color: #777;
    font: 300 0.8rem -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
    margin: 0.7rem 0; }
    #trackmeta p span {
      display: block;
      font-weight: 700;
      color: #151515; }

.trackcontent header {
  justify-content: space-between !important; }

.trackcontent ul#dots {
  display: flex;
  justify-content: space-around; }
  .trackcontent ul#dots li {
    list-style: none;
    background: white;
    border: 1px solid #ddd;
    border-radius: 50%;
    width: 7rem;
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    .trackcontent ul#dots li p {
      text-align: center;
      font-size: 1.3rem;
      -webkit-font-smoothing: antialiased; }
      .trackcontent ul#dots li p span {
        display: block;
        font-weight: 700;
        padding-top: 0.5rem;
        font-size: 0.7rem; }

#subinfo {
  font: 300 italic 0.8rem -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  color: #777;
  padding-bottom: 2rem; }

#dataanalysis {
  width: 100%;
  margin-top: 3rem;
  background: #26272b;
  color: white;
  border-collapse: collapse;
  font-size: 0.7rem;
  font-weight: 300;
  -webkit-font-smoothing: antialiased; }
  #dataanalysis td {
    border: solid 1px black;
    padding: 0.5rem;
    word-wrap: break-word;
    height: 25px; }
    #dataanalysis td:first-child {
      width: 140px;
      padding-left: 0.5rem; }
    #dataanalysis td:nth-child(2n) {
      border-right: none; }
    #dataanalysis td:last-child {
      text-align: right;
      width: 25px;
      border-left: none; }
    #dataanalysis td figure {
      background: linear-gradient(45deg, #27bea5 0%, #3684e6 100%);
      height: 0.8rem;
      margin: 0 0 0 0.5rem;
      border-radius: 0.3rem;
      border: 1px solid black; }
    #dataanalysis td img {
      width: 0.8rem;
      padding-left: 0.8rem; }

.tracklist {
  margin-left: 3rem;
  width: 100%; }
  .tracklist h2 {
    font-size: 1rem;
    margin: 0 0 0.5rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .tracklist h2 span {
      font-weight: 300;
      font-style: italic;
      -webkit-font-smoothing: antialiased;
      color: #777; }

.tp {
  border-left: 1px solid #ddd;
  padding: 1rem;
  box-sizing: border-box;
  height: 80px; }
  .tp p {
    font-size: 0.7rem; }

.tp-gradient {
  background: linear-gradient(45deg, #27bea5 0%, #3684e6 100%);
  width: 5px;
  height: 80px; }

/* Folders */
sidebar#folders {
  position: fixed;
  background: #26272b;
  bottom: 0;
  height: calc(100vh - 71px);
  width: 300px;
  z-index: 2;
  left: -300px;
  transition: 300ms; }
  sidebar#folders header {
    margin: 2rem 2rem 1rem; }
    sidebar#folders header svg {
      fill: #f5f5f5;
      vertical-align: sub;
      width: 1.3rem;
      height: 1.3rem; }
    sidebar#folders header h5 {
      color: #f5f5f5;
      display: inline;
      padding-left: 1rem; }
    sidebar#folders header img {
      width: 1rem;
      float: right; }
    sidebar#folders header #fline {
      border-bottom: 1px solid #f5f5f5;
      height: 1px;
      margin: 1rem 0 0 0; }
  sidebar#folders ul {
    margin: 0 2rem; }
    sidebar#folders ul li {
      list-style: none;
      color: white;
      font-size: .8rem;
      line-height: 1.4rem;
      -webkit-font-smoothing: antialiased; }
      sidebar#folders ul li img {
        height: 1rem;
        padding-right: .5rem; }
      sidebar#folders ul li a {
        display: flex;
        justify-content: space-between;
        align-items: center; }
      sidebar#folders ul li p {
        margin: 0;
        width: 200px;
        color: #f5f5f5;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer; }
        sidebar#folders ul li p:hover {
          text-decoration: underline; }
      sidebar#folders ul li span {
        background: #151515;
        border-radius: 0.3rem;
        text-align: center;
        color: #f5f5f5;
        width: 20px;
        height: 20px;
        font-size: .6rem;
        margin: .2rem 0; }

#folders.active {
  left: 0 !important; }

.note {
  background: none;
  border: none;
  width: 100%;
  resize: none;
  font-size: .8rem; }

#sharedwith {
  font-size: .8rem;
  margin: .5rem 0 2rem 0; }

.folderview h6 {
  margin-bottom: .8rem; }

.folderview .folderh {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  align-items: center; }
  .folderview .folderh .note {
    margin-bottom: 2rem; }
  .folderview .folderh h3 {
    margin: 0; }
  .folderview .folderh span {
    font-size: .8rem;
    color: #26272b; }
    .folderview .folderh span img {
      height: 1rem;
      padding-right: .4rem; }
    .folderview .folderh span p {
      display: inherit; }
    .folderview .folderh span a {
      display: inline-block;
      padding-right: 1.5rem; }
      .folderview .folderh span a:hover {
        opacity: .7; }

.folderview .btninvite {
  margin-left: 0; }

#folderspacer {
  height: 30px; }

ul#azlist {
  display: flex;
  justify-content: space-between; }
  ul#azlist li {
    background: #26272b;
    text-align: center;
    margin: .25rem;
    flex: 1; }
    ul#azlist li:hover {
      background: #777; }
    ul#azlist li a, ul#azlist li span {
      display: block;
      color: white;
      font-size: 1.2rem;
      line-height: 2.6rem; }
  ul#azlist .unused {
    background: #ddd; }
    ul#azlist .unused a {
      cursor: default; }
    ul#azlist .unused:hover {
      background: #ddd; }
  ul#azlist .active {
    background: linear-gradient(45deg, #27bea5 0%, #3684e6 100%); }

ul#listresult {
  -webkit-columns: 5;
  columns: 5; }
  ul#listresult li a {
    line-height: 1.5rem; }

form input {
  padding: 1rem;
  font-size: 0.8rem;
  height: 50px;
  width: 100%;
  flex: 1;
  transition: 0.1s all linear;
  border: 1px solid #ddd;
  border-left: none; }

input.warning {
  background: white url(/assets/img/warning.svg) no-repeat;
  background-position: right 1rem center;
  background-size: 1rem; }

.userbasic {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem 0 -0.5rem; }
  .userbasic .inputfield {
    display: flex;
    flex-direction: column; }
    .userbasic .inputfield .inputfield-group {
      display: flex;
      flex-direction: row-reverse; }
    .userbasic .inputfield .inputfield-description {
      color: gray;
      font-size: 12px;
      margin: 3px 0 0 0; }
    .userbasic .inputfield.vertical .inputfield-group {
      flex-direction: column-reverse; }
    .userbasic .inputfield.vertical input[type='text'],
    .userbasic .inputfield.vertical input[type='email'] {
      border-left: 1px solid #ddd; }
      .userbasic .inputfield.vertical input[type='text']:disabled + label,
      .userbasic .inputfield.vertical input[type='email']:disabled + label {
        background: inherit; }
      .userbasic .inputfield.vertical input[type='text']:focus + label,
      .userbasic .inputfield.vertical input[type='email']:focus + label {
        color: inherit;
        background: none; }
    .userbasic .inputfield.vertical label {
      border: 0;
      padding: 0;
      height: auto;
      line-height: 30px;
      float: initial; }
    .userbasic .inputfield input[type='text'],
    .userbasic .inputfield input[type='email'] {
      padding: 1rem;
      font-size: 0.8rem;
      height: 50px;
      width: 100%;
      flex: 1;
      transition: 0.1s all linear;
      border: 1px solid #ddd;
      border-left: none;
      float: right; }
      .userbasic .inputfield input[type='text']:disabled,
      .userbasic .inputfield input[type='email']:disabled {
        cursor: not-allowed;
        background: #ededed; }
        .userbasic .inputfield input[type='text']:disabled + label,
        .userbasic .inputfield input[type='email']:disabled + label {
          cursor: not-allowed;
          background: #e1e1e1; }
      .userbasic .inputfield input[type='text']:focus + label,
      .userbasic .inputfield input[type='email']:focus + label {
        color: white;
        background: #777; }
    .userbasic .inputfield label {
      color: #777;
      font-size: 0.8rem;
      transition: 100ms all linear;
      cursor: text;
      height: 50px;
      background: white;
      line-height: 50px;
      padding: 0 1rem;
      box-sizing: border-box;
      border: 1px solid #ddd;
      border-right: none;
      float: left; }
  .userbasic article,
  .userbasic button {
    flex: 1;
    margin: 0 0.5rem 1rem;
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 30%; }
    .userbasic article.active label,
    .userbasic button.active label {
      color: white;
      background: #777; }
  .userbasic label {
    color: #777;
    font-size: 0.8rem;
    transition: 100ms all linear;
    cursor: text;
    height: 50px;
    background: white;
    line-height: 50px;
    padding: 0 1rem;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-right: none; }

#related input {
  border-radius: 0.3rem 0.3rem 0 0 !important; }

#related div {
  background: white;
  width: 30rem;
  display: flex;
  margin: 0 auto;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-radius: 0 0 0.3rem 0.3rem;
  box-sizing: border-box;
  padding: 0.7rem; }

#related img {
  width: 3rem;
  height: 3rem; }

#related article {
  padding-left: 0.7rem;
  display: flex;
  font-size: 14px;
  align-items: center;
  width: 100%;
  justify-content: space-between; }

#related span {
  text-align: left; }

#related svg {
  width: 1rem;
  height: 1rem;
  padding-right: 1rem;
  opacity: 0.4;
  cursor: pointer; }
  #related svg:hover {
    opacity: 1; }

#related h3 {
  margin: 0.1rem 0; }

#related h4 {
  font-weight: 300;
  color: #777;
  margin: 0.1rem 0; }

.dropdown {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  border: 1px solid #ddd;
  background: white;
  /* Old browsers */ }

select {
  /* Make sure the select is wider than the container so we can clip the arrow */
  width: 110%;
  max-width: 110%;
  min-width: 110%;
  /* Remove select styling */
  appearance: none;
  -webkit-appearance: none;
  /* Ugly Firefox way of doing it */
  -moz-appearance: window;
  text-indent: 0.01px;
  text-overflow: '';
  /* Magic font size number to prevent iOS text zoom */
  font-size: 0.8rem;
  background: none;
  border: none;
  outline: none;
  /* Padding works surpringly well */
  padding: 1em 22% 1em 1em;
  margin: 4px; }

/* This hides native dropdown button arrow in IE */
select::-ms-expand {
  display: none; }

/* Custom arrow - could be an image, SVG, icon font, etc. */
.dropdown:after {
  background: none;
  content: url(../../assets/img/dropdown.svg);
  width: 1rem;
  padding: 0;
  position: absolute;
  right: 1em;
  top: 1em;
  bottom: 0.3em;
  z-index: 1;
  /* This hack makes the select behind the arrow clickable in some browsers */
  pointer-events: none; }

/* Focus style */
select:focus {
  outline: none; }

/* This hides focus around selected option in FF */
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000; }

#usertype {
  margin-right: 0.5rem; }

[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer; }

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none; }

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 200px;
  border-radius: 0.3rem;
  background-color: black;
  color: white;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 12px;
  line-height: 1.2; }

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid rgba(51, 51, 51, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: ' ';
  font-size: 0;
  line-height: 0; }

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1; }

.b {
  display: block; }

.toggle {
  position: relative;
  top: 50%;
  width: 50px;
  height: 30px;
  border-radius: 100px;
  background-color: #ddd;
  overflow: hidden;
  box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.05); }

.check {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6; }

.check:checked ~ .track {
  box-shadow: inset 0 0 0 20px #009688; }

.check:checked ~ .switch {
  right: 2px;
  left: 22px;
  transition: 0.15s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0.05s, 0s; }

.switch {
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  right: 22px;
  background-color: #fff;
  border-radius: 36px;
  z-index: 1;
  transition: 0.15s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0s, 0.05s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); }

.track {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.05);
  border-radius: 40px; }

.spinner {
  margin-top: 2rem; }

/* BUTTONS */
.btn {
  font: 700 0.8rem -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;
  background: #f5f5f5;
  padding: 5px 13px;
  color: gray;
  border: none;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  height: 50px;
  position: relative;
  text-transform: uppercase;
  text-align: center; }
  .btn:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    background-color: #e6e6e6 !important; }
    .btn:disabled.btn-icon:after {
      background-color: #dddddd !important; }
  .btn.btn-icon {
    padding: 0; }
    .btn.btn-icon:after {
      content: '';
      position: absolute;
      top: 0;
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: 17px;
      background-position: center center; }

.btn.w100 {
  width: 100%;
  padding-left: 0; }

.btn.left {
  padding-left: 50px; }

.btn.right {
  padding-right: 50px; }

.btn.green {
  background-color: #27bea5;
  color: white; }

.btn.black {
  background-color: #26272b;
  color: white; }

.btn.left:after {
  left: 0; }

.btn.right:after {
  right: 0; }

.btn.green:after,
button.green:hover {
  background-color: #2dadb7; }

.btn.black:after,
button.black:hover {
  background-color: #151515; }

ul#more {
  margin-top: 1rem;
  width: 100%;
  height: 50px;
  background: #26272b;
  color: white;
  position: relative;
  cursor: pointer; }
  ul#more:before {
    background: black url("../img/chevron-down.svg") no-repeat;
    content: '';
    position: absolute;
    top: 0;
    width: 50px;
    height: 50px;
    background-size: 17px;
    background-position: center center; }
  ul#more:hover {
    background: #151515; }
  ul#more:hover li {
    display: block; }
  ul#more span {
    padding-left: 50px;
    display: block; }
  ul#more img {
    width: 1rem;
    vertical-align: bottom;
    padding-right: 2rem; }
  ul#more h5 {
    margin: 0;
    line-height: 50px;
    text-align: center;
    color: white; }
  ul#more li {
    list-style: none;
    display: none;
    background: #26272b;
    font-size: 0.8rem; }
    ul#more li a {
      color: white;
      display: block;
      padding: 1rem; }
      ul#more li a:hover {
        background: #151515; }

.btngetrecommendations {
  width: 100%;
  background: #3684e6;
  border: none;
  color: white;
  height: 50px;
  cursor: pointer; }

.btnloadmore {
  width: 100%;
  margin-top: 1rem;
  background: #ddd;
  color: #777;
  transition: background 150ms; }
  .btnloadmore:hover {
    background: #26272b;
    color: #f5f5f5; }

.btnsave {
  width: 13rem;
  display: block; }

.btnsave2 {
  width: 100%; }

.btninvite {
  width: 13rem;
  margin-left: 1rem; }

.btngoback,
.btnplus {
  width: 10rem;
  margin-top: 2rem; }

.btnlogin,
.btnsend,
.btncheckout {
  width: 20rem; }

.btnbuy,
.btndownlaod {
  width: 30rem; }

.btnlogin:after {
  background-image: url("../img/login.svg"); }

.btngoback:after {
  background-image: url("../img/back.svg"); }

.btnsend:after,
.btninvite:after {
  background-image: url("../img/send.svg"); }

.btnbuy:after,
.btncheckout:after {
  background-image: url("../img/cart.svg"); }

.btnsave:after,
.btnsave2:after {
  background-image: url("../img/save.svg"); }

.btndownload:after {
  background-image: url("../img/download.svg"); }

.btnplus:after {
  background-image: url("../img/pluswhite.svg"); }

#statusbtn,
#reportbtn {
  position: fixed;
  left: 2rem;
  bottom: 2rem; }
  #statusbtn button,
  #reportbtn button {
    background: white;
    border: none;
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    border-bottom-width: 2px;
    border-radius: 50px;
    font-size: 10px;
    cursor: pointer; }
    #statusbtn button:hover,
    #reportbtn button:hover {
      border-color: #ccc; }

.ap#statusbtn,
.ap#reportbtn {
  bottom: calc(72px + 2rem); }

#reportbtn {
  right: 2rem;
  left: initial; }
  #reportbtn button {
    background: linear-gradient(45deg, #27bea5 0%, #3684e6 100%);
    color: white;
    border-color: #27bea5;
    line-height: 1rem; }
    #reportbtn button:hover {
      border-color: #3684e6; }

/* CARDS */
ul.tracks,
ul.artists,
.tracklist ol,
ul.users,
ul.uploads {
  margin: 0;
  padding: 0; }
  ul.tracks li,
  ul.artists li,
  .tracklist ol li,
  ul.users li,
  ul.uploads li {
    list-style: none;
    background: white;
    cursor: pointer;
    transition: all 300ms ease;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-bottom: 2px solid #ddd; }
    ul.tracks li:hover,
    ul.artists li:hover,
    .tracklist ol li:hover,
    ul.users li:hover,
    ul.uploads li:hover {
      box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.08);
      border-bottom: 2px solid #ccc; }

ul.tracks li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  height: 80px; }
  ul.tracks li section,
  ul.tracks li a {
    display: flex;
    align-items: center; }
  ul.tracks li a {
    border-left: 1px solid #ddd; }
    ul.tracks li a:hover .cardinfo {
      display: inline; }
    ul.tracks li a:hover .gototrack,
    ul.tracks li a:hover .addtoplaylist {
      padding-left: 15px; }
  ul.tracks li .cardinfo {
    box-sizing: border-box;
    font-size: 0.7rem;
    color: #777;
    padding-left: 20px;
    line-height: 75px;
    padding-right: 20px; }
    ul.tracks li .cardinfo:hover {
      background: #f8f8f8;
      border-bottom: 3px solid #3684e6;
      border-top: 1px solid #ddd; }
  ul.tracks li:first-child {
    margin-top: 0; }
  ul.tracks li:last-child {
    margin-bottom: 0; }
  ul.tracks li .gototrack,
  ul.tracks li .addtoplaylist {
    opacity: 0.3;
    width: 15px;
    margin: 0;
    padding: 25px 30px;
    height: 27px;
    transition: all 300ms ease; }
    ul.tracks li .gototrack:hover,
    ul.tracks li .addtoplaylist:hover {
      opacity: 0.6; }
  ul.tracks li .gototrack:before {
    content: url(../img/arrow-right.svg);
    display: block;
    line-height: 31px; }
  ul.tracks li .addtoplaylist:before {
    content: url(../img/plus.svg);
    display: block;
    line-height: 31px; }
  ul.tracks li .artwork {
    position: relative;
    width: 80px;
    height: 80px;
    margin-right: 1rem;
    background: black; }
    ul.tracks li .artwork img {
      position: absolute;
      opacity: 0.8; }
    ul.tracks li .artwork figure {
      margin: 0; }
    ul.tracks li .artwork .play,
    ul.tracks li .artwork .pause {
      opacity: 0.9;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      transform: translate(50%, 50%); }
      ul.tracks li .artwork .play:hover,
      ul.tracks li .artwork .pause:hover {
        opacity: 1; }
      ul.tracks li .artwork .play:before,
      ul.tracks li .artwork .pause:before {
        content: url(../img/play.svg); }
    ul.tracks li .artwork .pause:before {
      content: url(../img/pause.svg); }
  ul.tracks li section:nth-child(1) {
    flex: 1;
    min-width: 0; }
  ul.tracks li section:nth-child(1) span {
    display: block;
    flex-direction: column;
    margin-left: 0;
    flex: 1;
    min-width: 0; }
  ul.tracks li section:nth-child(2) {
    white-space: nowrap; }
  ul.tracks li h5,
  ul.tracks li h6 {
    margin: 0.4rem;
    margin-left: 0;
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  ul.tracks li h6 {
    font-weight: 300;
    color: #777; }
  ul.tracks li .cm {
    padding-right: 1rem;
    text-align: right; }
    ul.tracks li .cm h6 {
      font-size: 0.7rem;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      ul.tracks li .cm h6:before {
        height: 18px;
        background-size: 18px;
        background-repeat: no-repeat;
        margin-right: 0.5rem;
        display: inline-block;
        opacity: 0.5;
        width: 18px;
        content: ''; }
      ul.tracks li .cm h6.genres span {
        margin-right: 4px;
        color: #777; }
        ul.tracks li .cm h6.genres span:last-child {
          margin-right: 0; }
      ul.tracks li .cm h6.release-date:before {
        background-image: url(../img/calendar.svg); }
      ul.tracks li .cm h6.genres:before {
        background-image: url(../img/disc.svg); }
    ul.tracks li .cm .wave-hint {
      height: 50px;
      opacity: 0.12;
      border-left: 1px dashed #26272b;
      padding-left: 1rem;
      user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none; }
      @media (max-width: 1100px) {
        ul.tracks li .cm .wave-hint {
          display: none; } }
  ul.tracks li .artists {
    margin: 0.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    ul.tracks li .artists a {
      display: inline-block;
      border: 0;
      font-weight: 300;
      color: #777;
      padding-right: 5px; }
      ul.tracks li .artists a:hover {
        text-decoration: underline; }

ul.artists {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem; }
  @media only screen and (min-width: 500px) {
    ul.artists {
      grid-template-columns: 1fr 1fr; } }
  @media only screen and (min-width: 750px) {
    ul.artists {
      grid-template-columns: 1fr 1fr 1fr; } }
  @media only screen and (min-width: 1300px) {
    ul.artists {
      grid-template-columns: 1fr 1fr 1fr 1fr; } }
  @media only screen and (min-width: 1500px) {
    ul.artists {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr; } }
  @media only screen and (min-width: 1700px) {
    ul.artists {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; } }
  @media only screen and (min-width: 2000px) {
    ul.artists {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; } }
  @media only screen and (min-width: 2500px) {
    ul.artists {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; } }
  @media only screen and (min-width: 3000px) {
    ul.artists {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; } }
  ul.artists li {
    display: inline-block;
    position: relative;
    overflow: hidden;
    box-sizing: content-box; }
    ul.artists li:first-child {
      margin-left: 0; }
    ul.artists li:hover {
      filter: grayscale(1); }
    ul.artists li figure {
      position: relative;
      margin: 0; }
    ul.artists li img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      object-position: 0 0; }
    ul.artists li span {
      display: flex;
      flex-direction: column;
      height: 55px; }
    ul.artists li h5,
    ul.artists li h6 {
      margin: 0.2rem 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    ul.artists li h5 {
      margin-top: 0.6rem; }
    ul.artists li h6 {
      font-weight: 300;
      color: #777; }
    ul.artists li .triangle {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 55px;
      margin: 0;
      border-style: solid;
      border-width: 0 0 1rem 20rem;
      border-color: transparent transparent white transparent; }

ul.users li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  cursor: default; }
  ul.users li:first-child {
    margin-top: 0; }
  ul.users li:last-child {
    margin-bottom: 0; }
  ul.users li p {
    margin-left: 1rem;
    font-size: 0.8rem; }
    ul.users li p span {
      color: #777; }
  ul.users li section,
  ul.users li a {
    display: flex;
    align-items: center; }
  ul.users li .deleteuser,
  ul.users li .dl {
    border-left: 1px solid #ddd;
    padding: 1rem; }
  ul.users li .dl {
    content: url(../img/downloadblack.svg); }

.deleteuser,
.dl {
  content: url(../img/trashblack.svg);
  opacity: 0.3;
  width: 15px;
  margin: 0;
  transition: all 300ms ease; }
  .deleteuser:hover,
  .dl:hover {
    opacity: 1; }

.tracklist ol {
  margin: 0;
  padding: 0; }
  .tracklist ol section {
    position: relative; }
  .tracklist ol section:nth-child(2) {
    white-space: nowrap; }
  .tracklist ol li {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 0.5rem 0;
    height: 50px;
    width: 100%; }
    .tracklist ol li a {
      border-left: 1px solid #ddd;
      display: flex;
      line-height: 50px; }
      .tracklist ol li a:hover .cardinfo {
        display: inline; }
      .tracklist ol li a:hover .gototrack,
      .tracklist ol li a:hover .addtoplaylist {
        padding-left: 15px; }
    .tracklist ol li .number {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      width: 48px;
      background: #26272b;
      margin: 0;
      color: white;
      flex-shrink: 0; }
    .tracklist ol li .play,
    .tracklist ol li .pause {
      opacity: 0.9;
      width: 1.75crem;
      margin: 0;
      background: #26272b;
      position: absolute;
      top: 23px;
      left: 25px;
      z-index: 9;
      transform: translate(-50%, -50%);
      cursor: pointer; }
      .tracklist ol li .play:hover,
      .tracklist ol li .pause:hover {
        opacity: 1; }
      .tracklist ol li .play:before,
      .tracklist ol li .pause:before {
        content: url(../img/play-small.svg); }
    .tracklist ol li .pause {
      content: url(../img/pause-small.svg); }
    .tracklist ol li h4 {
      margin: 0 0 0 1rem;
      font-size: 0.8rem;
      align-self: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .tracklist ol li h4 time {
        font-weight: 300;
        font-style: italic;
        color: #777;
        padding-left: 0.5rem; }
    .tracklist ol li:hover .gototrack,
    .tracklist ol li:hover .addtoplaylist {
      display: inline; }
    .tracklist ol li .gototrack,
    .tracklist ol li .addtoplaylist {
      opacity: 0.3;
      width: 15px;
      margin: 0;
      padding: 17px;
      transition: all 300ms ease; }
      .tracklist ol li .gototrack:hover,
      .tracklist ol li .addtoplaylist:hover {
        opacity: 0.6; }
    .tracklist ol li .gototrack:before {
      content: url(../img/arrow-right.svg);
      display: block;
      line-height: 18px; }
    .tracklist ol li .addtoplaylist:before {
      content: url(../img/plus.svg);
      display: block;
      line-height: 18px; }
    .tracklist ol li .cardinfo {
      font-size: 0.7rem;
      color: #777;
      padding: 0 20px;
      height: 44px; }
      .tracklist ol li .cardinfo:hover {
        background: #f8f8f8;
        border-bottom: 3px solid #3684e6;
        border-top: 1px solid #ddd; }

.genre {
  text-align: center; }
  .genre span {
    height: 35px !important; }

.dragdrop {
  width: 3rem;
  height: 65px;
  margin: 0;
  border-right: 1px solid #ddd;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center; }
  .dragdrop svg {
    fill: red; }

.aplay li:hover .cover-wp .cover-pl-wp, .aplay li.playing .cover-wp .cover-pl-wp {
  opacity: 1; }

.aplay li .cover-wp {
  position: relative; }
  .aplay li .cover-wp .cover-pl-wp {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .aplay li .cover-wp .cover-pl-wp:before {
      content: ' ';
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 1; }
    .aplay li .cover-wp .cover-pl-wp .pause,
    .aplay li .cover-wp .cover-pl-wp .play {
      cursor: pointer; }
    .aplay li .cover-wp .cover-pl-wp .pause {
      content: url(../img/pause.svg); }
    .aplay li .cover-wp .cover-pl-wp .play {
      content: url(../img/play.svg);
      /* background-image:url(../img/play.svg);
            background-repeat: no-repeat;
            background-position: center center;
            display: inline-block;
            width: 400px;
            height: 400px;
            content:""; */ }

/* NOTIFICATION */
#nbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 6;
  height: 70px;
  display: table;
  color: white; }
  #nbar p {
    display: table-cell;
    position: relative;
    font-size: 1rem;
    line-height: 70px;
    margin: 0;
    -webkit-font-smoothing: antialiased; }
    #nbar p span {
      font-weight: 700; }
  #nbar.relative {
    position: relative; }
  #nbar .nbar-icon {
    display: table-cell;
    vertical-align: middle; }

.blue#nbar {
  background: #3684e6; }

.yellow#nbar {
  background: #ffa800; }

.black#nbar {
  background: #151515; }

.red#nbar {
  background: #cd2134; }

.green#nbar {
  background: #27bea5; }

.slideup {
  animation: su 300ms both; }

.slidedown {
  animation: sd 300ms both; }

.fadein {
  animation: fi 300ms both; }

.fadeout {
  animation: fo 300ms both; }

@keyframes su {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes sd {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0); } }

@keyframes fi {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fo {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

/* CARDS */
.ar li {
  animation: ar 300ms both; }

.cardinfo {
  animation: ci 300ms both; }

.ar li:nth-child(2) {
  animation-delay: 25ms; }

.ar li:nth-child(3) {
  animation-delay: 50ms; }

.ar li:nth-child(4) {
  animation-delay: 75ms; }

.ar li:nth-child(5) {
  animation-delay: 100ms; }

.ar li:nth-child(6) {
  animation-delay: 125ms; }

.ar li:nth-child(7) {
  animation-delay: 150ms; }

.ar li:nth-child(8) {
  animation-delay: 175ms; }

.ar li:nth-child(9) {
  animation-delay: 200ms; }

.ar li:nth-child(10) {
  animation-delay: 225ms; }

.ar li:nth-child(11) {
  animation-delay: 250ms; }

.ar li:nth-child(12) {
  animation-delay: 275ms; }

.ar li:nth-child(13) {
  animation-delay: 300ms; }

.ar li:nth-child(14) {
  animation-delay: 325ms; }

.ar li:nth-child(15) {
  animation-delay: 350ms; }

.ar li:nth-child(16) {
  animation-delay: 375ms; }

.ar li:nth-child(17) {
  animation-delay: 400ms; }

.ar li:nth-child(18) {
  animation-delay: 425ms; }

.ar li:nth-child(19) {
  animation-delay: 450ms; }

.ar li:nth-child(20) {
  animation-delay: 475ms; }

.ar li:nth-child(21) {
  animation-delay: 475ms; }

.ar li:nth-child(22) {
  animation-delay: 500ms; }

.ar li:nth-child(23) {
  animation-delay: 525ms; }

.ar li:nth-child(24) {
  animation-delay: 550ms; }

.ar li:nth-child(25) {
  animation-delay: 575ms; }

.ar li:nth-child(26) {
  animation-delay: 600ms; }

.ar li:nth-child(27) {
  animation-delay: 625ms; }

.ar li:nth-child(28) {
  animation-delay: 650ms; }

.ar li:nth-child(29) {
  animation-delay: 675ms; }

.ar li:nth-child(30) {
  animation-delay: 700ms; }

.ar li:nth-child(31) {
  animation-delay: 725ms; }

.ar li:nth-child(32) {
  animation-delay: 750ms; }

.ar li:nth-child(33) {
  animation-delay: 775ms; }

.ar li:nth-child(34) {
  animation-delay: 800ms; }

.ar li:nth-child(35) {
  animation-delay: 825ms; }

.ar li:nth-child(36) {
  animation-delay: 850ms; }

.ar li:nth-child(37) {
  animation-delay: 875ms; }

.ar li:nth-child(38) {
  animation-delay: 900ms; }

.ar li:nth-child(39) {
  animation-delay: 925ms; }

.ar li:nth-child(40) {
  animation-delay: 950ms; }

@keyframes ar {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes ci {
  from {
    opacity: 0;
    -webkit-transform: translate3d(25%, 0, 0);
    transform: translate3d(25%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

/* CONTENTHEADER */
#cheader {
  animation: aimg linear 50s infinite both; }

@keyframes aimg {
  0% {
    background-position-y: calc(0% + 20%); }
  50% {
    background-position-y: calc(0% + 80%); }
  100% {
    background-position-y: calc(0% + 20%); } }

/* AUDIOFEATURES */
.aw figure {
  animation: aw 2s normal; }

@keyframes aw {
  0% {
    width: 0%; } }

/* Status message */
#status {
  background: white;
  bottom: -100vh;
  width: 100%;
  transition: all 300ms ease;
  box-sizing: border-box;
  z-index: 99; }
  #status iframe {
    display: block;
    border: none;
    width: 100%;
    height: 100%;
    position: absolute; }
  #status #close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    z-index: 100; }
    #status #close:hover {
      opacity: 0.8; }

#status.active {
  top: 0 !important; }

/* Filters */
#filters {
  float: left;
  min-width: 400px;
  display: inline-block;
  margin-right: 3rem; }
  @media (max-width: 1300px) {
    #filters {
      min-width: 250px; } }
  #filters h3 {
    font-size: 1.3rem; }
  #filters ul li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem; }
    @media (max-width: 1300px) {
      #filters ul li {
        flex-direction: column; } }
    #filters ul li label {
      font-size: 0.9rem;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      color: #151515;
      max-width: 40%; }
      #filters ul li label span {
        display: block;
        font-size: 11px;
        margin-top: 10px;
        font-style: italic;
        color: #777; }
    @media (max-width: 1300px) {
      #filters ul li > label {
        margin-bottom: 0.75rem; }
      #filters ul li .dropdown {
        width: 100%; } }
  #filters ul .dottedline {
    border-top: 1px dashed #ddd;
    margin: 2rem 0; }
  #filters ul .dropdown {
    width: 220px; }
  #filters ul .ftog {
    width: 220px;
    align-self: flex-end; }
  #filters ul .multiply {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    #filters ul .multiply p {
      font-size: 0.7rem;
      padding: 0 0.4rem;
      color: #555; }
    #filters ul .multiply .dropdown {
      width: 101px; }
  #filters ul .slider-wrapper {
    margin: 0 15px 0 7px;
    width: 50%; }

#filteredcontent {
  width: auto;
  overflow: hidden;
  padding-left: 1rem; }
  #filteredcontent .dropdown {
    width: 11rem;
    margin-bottom: 2rem; }
  #filteredcontent h3 {
    font-weight: 300;
    padding-right: 1rem; }
  #filteredcontent li {
    margin-bottom: 0.5rem; }

#showfilter {
  border-bottom: 2px solid #ccc;
  background: #e9e9e9;
  text-align: center; }
  #showfilter p {
    margin: 0;
    padding: 15px 0 15px 7px;
    display: inline-block;
    vertical-align: super;
    color: #777; }

#selectedfilters li {
  background: #ddd;
  padding: 0.5rem 1rem 0.6rem 1rem;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 2rem;
  margin-right: 1rem; }
  #selectedfilters li:hover {
    opacity: 0.8; }
  #selectedfilters li h5 {
    display: inline;
    padding-right: 10px; }
    #selectedfilters li h5 span {
      font-weight: 300; }
    #selectedfilters li h5:after {
      content: '';
      display: inline-block;
      background: url(../img/close.svg) no-repeat;
      width: 10px;
      height: 10px;
      float: right;
      margin-top: 6px; }
  #selectedfilters li img {
    width: 10px;
    height: 10px;
    display: inline-block; }
    #selectedfilters li img:hover {
      opacity: 0.9; }

ul.cblist {
  width: 223px;
  box-sizing: border-box; }
  ul.cblist [type='checkbox']:checked,
  ul.cblist [type='checkbox']:not(:checked) {
    position: absolute;
    left: -9999px; }
  ul.cblist [type='checkbox']:checked + label,
  ul.cblist [type='checkbox']:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666; }
  ul.cblist [type='checkbox']:checked + label:before,
  ul.cblist [type='checkbox']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    background: #fff; }
  ul.cblist [type='checkbox']:checked + label:after,
  ul.cblist [type='checkbox']:not(:checked) + label:after {
    content: '';
    width: 14px;
    height: 14px;
    background: #009688;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: all 0.2s ease; }
  ul.cblist [type='checkbox']:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0); }
  ul.cblist [type='checkbox']:checked + label:after {
    opacity: 1;
    transform: scale(1); }
  ul.cblist [type='checkbox']:disabled:checked + label:before,
  ul.cblist [type='checkbox']:disabled:not(:checked) + label:before {
    border-color: #ddd;
    background-color: white; }
  ul.cblist [type='checkbox']:disabled:checked + label:after {
    background: #ddd; }
  ul.cblist li {
    justify-content: flex-start !important;
    margin-bottom: 0.5rem !important; }
    ul.cblist li label {
      font-size: 0.8rem; }
  ul.cblist #settings {
    justify-content: space-between !important;
    margin-top: 1rem; }
    ul.cblist #settings button {
      background: transparent;
      border: none;
      margin-top: 0;
      font-size: 0.8rem;
      text-decoration: underline;
      color: #777;
      width: auto;
      padding: 0;
      cursor: pointer; }
      ul.cblist #settings button:disabled {
        cursor: not-allowed; }

.plfilters {
  flex-direction: column !important; }
  .plfilters #artwork {
    opacity: 1; }
  .plfilters #filters {
    min-width: 200px;
    margin-right: 0;
    margin-top: 2rem; }
    .plfilters #filters ul .dropdown {
      width: 100%; }
    .plfilters #filters .slider-wrapper {
      width: 92%; }
  .plfilters li {
    flex-direction: column;
    margin-bottom: 1.5rem !important; }
    .plfilters li #settings {
      flex-direction: row; }
    .plfilters li .cblist {
      width: 100%; }
    .plfilters li label {
      margin-bottom: 1rem;
      max-width: none !important; }
    .plfilters li form label {
      margin-bottom: 0; }

.singlepl {
  margin-top: 0; }

/* Ingestion & Related */
.production-item {
  background: white;
  border: 1px solid #efefef; }
  .production-item.open header {
    border-bottom: 1px solid #efefef; }
  .production-item.open .production-item-content {
    display: flex; }
  .production-item.production-item-track {
    transition: 0.1s linear; }
    .production-item.production-item-track header {
      min-height: 40px;
      border-bottom: 0; }
    .production-item.production-item-track button.btn-gray {
      min-height: 30px; }
    .production-item.production-item-track.open {
      transform: scale(1.03);
      margin: 10px 0 20px 0;
      box-shadow: rgba(0, 0, 0, 0.45) 0 15px 25px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      transition: 0.2s linear; }
  .production-item .items-state-group {
    margin-bottom: 5px;
    padding: 10px; }
    .production-item .items-state-group.open h4 {
      margin: 10px 0 20px 0; }
    .production-item .items-state-group .item-state-heading .btn {
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer; }
    .production-item .items-state-group:last-child {
      margin: 0; }
  .production-item .production-item-content {
    padding: 15px;
    display: none;
    flex-direction: column; }
    .production-item .production-item-content .production-item-controls,
    .production-item .production-item-content .production-item-list {
      display: flex;
      flex: 1; }
    .production-item .production-item-content .production-item-list {
      flex-direction: column; }
      .production-item .production-item-content .production-item-list a {
        padding-top: 3px;
        text-decoration: underline;
        display: block;
        font-size: 1.5rem; }
      .production-item .production-item-content .production-item-list span.production-item-list-status {
        margin-top: -3px;
        margin-left: 35px;
        vertical-align: middle;
        display: inline-block;
        align-self: flex-end; }
    .production-item .production-item-content .production-item-controls {
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 20px; }
      .production-item .production-item-content .production-item-controls .btn {
        margin-left: 10px; }
        .production-item .production-item-content .production-item-controls .btn:first-child {
          margin-left: 0; }
  .production-item header {
    margin: 0 !important;
    min-height: 60px;
    display: flex;
    align-items: initial !important; }
    .production-item header .colp {
      display: flex;
      flex: 1;
      font-size: 0.9rem;
      justify-content: center;
      align-content: center;
      align-items: center;
      border-right: 1px solid #efefef;
      text-align: center; }
      .production-item header .colp:last-child {
        border-right: 0; }
      .production-item header .colp.colp-2x {
        flex: 1.5; }
      .production-item header .colp.colp-has-btn {
        align-items: inherit; }
        .production-item header .colp.colp-has-btn .btn {
          flex: 1;
          height: auto; }

.ingestion .c {
  margin-top: 6rem; }
  .ingestion .c:first-child {
    margin: 0; }

.ingestion p {
  font-size: 1rem;
  line-height: 1.2rem;
  padding-bottom: 1rem; }

.ingestion .approved {
  display: flex;
  justify-content: space-between;
  align-items: baseline; }
  .ingestion .approved .multiply {
    width: 100px; }

.ingestion .status-green {
  margin: 1rem;
  width: 1rem;
  height: 1rem;
  background: #27bea5;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: pulse 2s infinite; }

.ingestion ul {
  margin-bottom: 1rem; }

.ingestion h1 {
  margin-top: 5rem; }

.ingestion h3 {
  font-weight: 300; }

.ingestion .clientlogo,
.ingestion .orlogo {
  position: absolute;
  width: 150px;
  top: calc(2rem + 15px);
  left: 2rem; }
  .ingestion .clientlogo img,
  .ingestion .orlogo img {
    max-width: 100%;
    max-height: 100%; }

.ingestion .orlogo {
  width: 75px;
  right: 2rem;
  left: initial; }

.ingestion .clientlogo {
  height: 100px; }

.ingestion svg {
  margin: 3rem auto;
  width: 150px;
  height: 150px;
  display: block; }
  .ingestion svg .cls-1 {
    stroke: #27bea5;
    fill: none;
    stroke-width: 3px; }

.ingestion .circle {
  stroke-dasharray: 700;
  stroke-dashoffset: 700; }

.ingestion .checkmark {
  stroke-dasharray: 150;
  stroke-dashoffset: 150; }

.ingestion .run-animation .circle {
  animation: 2.5s circleDraw forwards; }

.ingestion .run-animation .checkmark {
  animation: 0.75s checkmarkDraw forwards;
  animation-delay: 1s; }

.fbc {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 15px;
  background: linear-gradient(45deg, #27bea5 0%, #3684e6 100%);
  margin: 0; }

.feedback li {
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start !important; }
  .feedback li section,
  .feedback li form {
    width: 100%; }
  .feedback li p.filename {
    font-weight: 700;
    margin: 0.5rem 0 0 0 !important; }
  .feedback li article {
    margin-bottom: 0; }
    .feedback li article:last-child {
      margin-right: 0; }
    .feedback li article:first-child {
      margin-left: 0; }
  .feedback li .userbasic {
    margin: 0; }
  .feedback li .note {
    margin-top: 1rem; }
  .feedback li form {
    margin: 0; }

.sendin {
  margin-top: 2rem; }

.fcom {
  text-align: center; }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(39, 190, 165, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(39, 190, 165, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(39, 190, 165, 0); } }

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(39, 190, 165, 0.4);
    box-shadow: 0 0 0 0 rgba(39, 190, 165, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(39, 190, 165, 0);
    box-shadow: 0 0 0 10px rgba(39, 190, 165, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(39, 190, 165, 0);
    box-shadow: 0 0 0 0 rgba(39, 190, 165, 0); } }

#wupload {
  display: flex;
  justify-content: space-between; }
  #wupload .upwrap {
    width: 48%; }
    #wupload .upwrap .uploadfield {
      border: 1px solid #ddd;
      display: flex;
      padding: 4rem;
      justify-content: center;
      align-content: center;
      text-align: center;
      cursor: pointer;
      margin-bottom: 1rem; }
      #wupload .upwrap .uploadfield:hover img {
        opacity: 0.3; }
      #wupload .upwrap .uploadfield p {
        font-size: 1rem; }
        #wupload .upwrap .uploadfield p span {
          font-weight: 700; }
      #wupload .upwrap .uploadfield img {
        width: 100px;
        opacity: 0.1; }
    #wupload .upwrap li {
      padding: 0 1rem;
      margin-bottom: 1rem; }
      #wupload .upwrap li span {
        display: flex;
        justify-content: space-between; }
        #wupload .upwrap li span .error,
        #wupload .upwrap li span .delete {
          text-decoration: underline;
          text-underline-position: under; }
        #wupload .upwrap li span .error {
          color: #cd2134; }
      #wupload .upwrap li:last-child {
        margin-bottom: 0; }
      #wupload .upwrap li p {
        margin-bottom: 0; }
      #wupload .upwrap li progress {
        width: 100%;
        margin-top: 0.5rem; }

progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  appearance: none;
  border-radius: 5rem;
  height: 8px; }

progress[value]::-webkit-progress-bar {
  background-color: #ddd;
  border-radius: 5rem; }

progress[value]::-webkit-progress-value {
  background-image: linear-gradient(45deg, #27bea5 0%, #3684e6 100%);
  border-radius: 5rem; }

.uploadnotes li {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 0.5rem; }
  .uploadnotes li p {
    font-size: 1rem;
    padding-right: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 20%; }
  .uploadnotes li .userbasic {
    width: 80%; }
    .uploadnotes li .userbasic input {
      border-left: 1px solid #ddd;
      padding: 0 1rem; }

@keyframes circleDraw {
  from {
    stroke-dashoffset: 700; }
  to {
    stroke-dashoffset: 0; } }

@keyframes checkmarkDraw {
  from {
    stroke-dashoffset: 150; }
  to {
    stroke-dashoffset: 0; } }

.upload-heading {
  color: gray; }

.upload-controls {
  color: #bfbdbd;
  display: flex;
  align-items: flex-end;
  flex-direction: row-reverse;
  margin: 5px 0; }
  .upload-controls .upload-control {
    margin: 5px; }
    .upload-controls .upload-control:hover {
      color: #a3a2a2; }
    .upload-controls .upload-control:first-child {
      margin-right: 0; }

/* BUTTONS */
.rc-slider-rail {
  overflow: visible;
  background: #ddd;
  border-radius: 3px;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.05);
  height: 6px;
  border: 1px solid #d8d8d8; }

.rc-slider-track {
  background: linear-gradient(45deg, #27bea5 0%, #3684e6 100%);
  position: absolute;
  height: 6px; }

.rc-slider-handle {
  border: none;
  border-radius: 100%;
  height: 24px;
  outline: none;
  z-index: 2;
  width: 24px;
  margin-top: -9px; }
  .rc-slider-handle.rc-slider-handle-1 {
    background: #27bea5; }
  .rc-slider-handle.rc-slider-handle-2 {
    background: #3684e6; }
