@import 'variables';

/* Track page */

#trackinfo,
.tracklistinfo {
  display: flex;
  margin-top: 4rem;
  #artwork {
    width: 300px;
    height: 300px;
    opacity: 0.8;
  }
  section {
    display: flex;
    flex-direction: column;
    .tleft {
      margin: 0;
      width: 300px;
      height: 300px;
      position: relative;
      &.overlay {
        background: black;
      }
      #flag {
        width: 100%;
        max-width: 250px;
        max-height: 250px;
      }
      a .play {
        position: absolute;
        top: 45%;
        left: 50%;
        width: 50px;
        transform: translate(-50%, 0);
        &:hover {
          opacity: 0.7;
        }
      }
      figure {
        margin: 0;
      }
      figure.play,
      figure.pause {
        content: url(../img/play.svg);
        opacity: 0.9;
        width: 5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 9;
        transform: translate(-50%, -50%);
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }
      figure.pause {
        content: url(../img/pause.svg);
      }
    }
    button {
      width: 300px;
      margin-top: 1rem;
    }
  }
}
.tracklistinfo section {
  flex-direction: row;
  border: none;
}

#trackmeta {
  margin-left: 3rem;
  h1 {
    margin-bottom: 0.5rem;
  }
  h2 {
    margin: 0;
    opacity: 0.5;
    &:hover {
      opacity: 0.3;
    }
    // &:after{
    //   content: "";
    //   display: inline-block;
    //   background: url("../img/chevron-right.svg") no-repeat;
    //   background-position-y: 6px;
    //   width: 1.5rem;
    //   height: 1.5rem;
    // }
  }
  p {
    color: $lightblack;
    font: 300 0.8rem $fontstack;
    margin: 0.7rem 0;

    span {
      display: block;
      font-weight: 700;
      color: $black;
    }
  }
}

.trackcontent {
  header {
    justify-content: space-between !important;
  }
  ul#dots {
    display: flex;
    justify-content: space-around;
    li {
      list-style: none;
      background: white;
      border: 1px solid $bordercolor;
      border-radius: 50%;
      width: 7rem;
      height: 7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        text-align: center;
        font-size: 1.3rem;
        -webkit-font-smoothing: antialiased;
        span {
          display: block;
          font-weight: 700;
          padding-top: 0.5rem;
          font-size: 0.7rem;
        }
      }
    }
  }
}
#subinfo {
  font: 300 italic 0.8rem $fontstack;
  color: $lightblack;
  padding-bottom: 2rem;
}

#dataanalysis {
  width: 100%;
  margin-top: 3rem;
  background: $bgblack;
  color: white;
  border-collapse: collapse;
  font-size: 0.7rem;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  td {
    border: solid 1px black;
    padding: 0.5rem;
    word-wrap: break-word;
    height: 25px;
    &:first-child {
      width: 140px;
      padding-left: 0.5rem;
    }
    &:nth-child(2n) {
      border-right: none;
    }
    &:last-child {
      text-align: right;
      width: 25px;
      border-left: none;
    }
    figure {
      background: $gradient;
      height: 0.8rem;
      margin: 0 0 0 0.5rem;
      border-radius: $radius;
      border: 1px solid black;
    }
    img {
      width: 0.8rem;
      padding-left: 0.8rem;
    }
  }
}

.tracklist {
  margin-left: 3rem;
  width: 100%;
  h2 {
    font-size: 1rem;
    margin: 0 0 0.5rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      font-weight: 300;
      font-style: italic;
      -webkit-font-smoothing: antialiased;
      color: $lightblack;
    }
  }
}

.tp {
  border-left: 1px solid $bordercolor;
  padding: 1rem;
  box-sizing: border-box;
  height: 80px;
  p {
    font-size: 0.7rem;
  }
}

.tp-gradient {
  background: $gradient;
  width: 5px;
  height: 80px;
}
