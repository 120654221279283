@import 'variables';

/* BUTTONS */

.btn {
  font: 700 0.8rem $fontstack;
  background: #f5f5f5;
  padding: 5px 13px;
  color: gray;
  border: none;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  height: 50px;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    background-color: #e6e6e6 !important;
    &.btn-icon {
      &:after {
        background-color: #dddddd !important;
      }
    }
  }
  &.btn-icon {
    padding: 0;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: 17px;
      background-position: center center;
    }
  }
}

.btn.w100 {
  width: 100%;
  padding-left: 0;
}

.btn.left {
  padding-left: 50px;
}
.btn.right {
  padding-right: 50px;
}
.btn.green {
  background-color: $green;
  color: white;
}
.btn.black {
  background-color: $bgblack;
  color: white;
}

.btn.left:after {
  left: 0;
}
.btn.right:after {
  right: 0;
}
.btn.green:after,
button.green:hover {
  background-color: $darkgreen;
}
.btn.black:after,
button.black:hover {
  background-color: $black;
}

// DROPDOWN BUTTON

ul#more {
  margin-top: 1rem;
  width: 100%;
  height: 50px;
  background: $bgblack;
  color: white;
  position: relative;
  cursor: pointer;
  &:before {
    background: black url('../img/chevron-down.svg') no-repeat;
    content: '';
    position: absolute;
    top: 0;
    width: 50px;
    height: 50px;
    background-size: 17px;
    background-position: center center;
  }
  &:hover {
    background: $black;
  }
  &:hover li {
    display: block;
  }
  span {
    padding-left: 50px;
    display: block;
  }
  img {
    width: 1rem;
    vertical-align: bottom;
    padding-right: 2rem;
  }
  h5 {
    margin: 0;
    line-height: 50px;
    text-align: center;
    color: white;
  }
  li {
    list-style: none;
    display: none;
    background: $bgblack;
    font-size: 0.8rem;
    a {
      color: white;
      display: block;
      padding: 1rem;
      &:hover {
        background: $black;
      }
    }
  }
}

// Custom btns

.btngetrecommendations {
  width: 100%;
  background: $blue;
  border: none;
  color: white;
  height: 50px;
  cursor: pointer;
}

.btnloadmore {
  width: 100%;
  margin-top: 1rem;
  background: $bordercolor;
  color: $lightblack;
  transition: background 150ms;
  &:hover {
    background: $bgblack;
    color: $bg;
  }
}

.btnsave {
  width: 13rem;
  display: block;
}

.btnsave2 {
  width: 100%;
}

.btninvite {
  width: 13rem;
  margin-left: 1rem;
}

.btngoback,
.btnplus {
  width: 10rem;
  margin-top: 2rem;
}

.btnlogin,
.btnsend,
.btncheckout {
  width: 20rem;
}

.btnbuy,
.btndownlaod {
  width: 30rem;
}

.btnlogin:after {
  background-image: url('../img/login.svg');
}

.btngoback:after {
  background-image: url('../img/back.svg');
}

.btnsend:after,
.btninvite:after {
  background-image: url('../img/send.svg');
}

.btnbuy:after,
.btncheckout:after {
  background-image: url('../img/cart.svg');
}

.btnsave:after,
.btnsave2:after {
  background-image: url('../img/save.svg');
}

.btndownload:after {
  background-image: url('../img/download.svg');
}

.btnplus:after {
  background-image: url('../img/pluswhite.svg');
}

#statusbtn,
#reportbtn {
  position: fixed;
  left: 2rem;
  bottom: 2rem;
  button {
    background: white;
    border: none;
    padding: 0.5rem 1rem;
    border: 1px solid $bordercolor;
    border-bottom-width: 2px;
    border-radius: 50px;
    font-size: 10px;
    cursor: pointer;
    &:hover {
      border-color: #ccc;
    }
  }
}

.ap#statusbtn,
.ap#reportbtn {
  bottom: calc(72px + 2rem);
}

#reportbtn {
  right: 2rem;
  left: initial;
  button {
    background: $gradient;
    color: white;
    border-color: $green;
    line-height: 1rem;
    &:hover {
      border-color: $blue;
    }
  }
}
