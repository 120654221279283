@import 'variables';
/* Filters */

#filters {
  // width: 25%;
  float: left;
  min-width: 400px;
  display: inline-block;
  margin-right: 3rem;
  @media (max-width: 1300px) {
    min-width: 250px;
  }
  h3 {
    font-size: 1.3rem;
  }
  ul {
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      @media (max-width: 1300px) {
        flex-direction: column;
      }
      label {
        font-size: 0.9rem;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        color: #151515;
        max-width: 40%;
        span {
          display: block;
          font-size: 11px;
          margin-top: 10px;
          font-style: italic;
          color: $lightblack;
        }
      }
      @media (max-width: 1300px) {
        > label {
          margin-bottom: 0.75rem;
        }
        .dropdown {
          width: 100%;
        }
      }
    }
    .dottedline {
      border-top: 1px dashed #ddd;
      margin: 2rem 0;
    }
    .dropdown {
      width: 220px;
    }
    .ftog {
      width: 220px;
      align-self: flex-end;
    }
    .multiply {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 0.7rem;
        padding: 0 0.4rem;
        color: #555;
      }
      .dropdown {
        width: 101px;
      }
    }
    .slider-wrapper {
      margin: 0 15px 0 7px;
      width: 50%;
    }
  }
}

#filteredcontent {
  width: auto;
  overflow: hidden;
  padding-left: 1rem;
  .dropdown {
    width: 11rem;
    margin-bottom: 2rem;
  }
  h3 {
    font-weight: 300;
    padding-right: 1rem;
  }
  ul {
  }
  li {
    margin-bottom: 0.5rem;
  }
}

#showfilter {
  border-bottom: 2px solid #ccc;
  background: #e9e9e9;
  text-align: center;
  p {
    margin: 0;
    padding: 15px 0 15px 7px;
    display: inline-block;
    vertical-align: super;
    color: #777;
  }
}

#selectedfilters {
  li {
    background: #ddd;
    padding: 0.5rem 1rem 0.6rem 1rem;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 2rem;
    margin-right: 1rem;
    &:hover {
      opacity: 0.8;
    }
    h5 {
      display: inline;
      padding-right: 10px;
      span {
        font-weight: 300;
      }
      &:after {
        // content:url(../img/close.svg);
        content: '';
        display: inline-block;
        background: url(../img/close.svg) no-repeat;
        width: 10px;
        height: 10px;
        float: right;
        margin-top: 6px;
      }
    }
    img {
      width: 10px;
      height: 10px;
      display: inline-block;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}

ul.cblist {
  width: 223px;
  box-sizing: border-box;
  [type='checkbox']:checked,
  [type='checkbox']:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type='checkbox']:checked + label,
  [type='checkbox']:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }
  [type='checkbox']:checked + label:before,
  [type='checkbox']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    background: #fff;
  }
  [type='checkbox']:checked + label:after,
  [type='checkbox']:not(:checked) + label:after {
    content: '';
    width: 14px;
    height: 14px;
    background: $checkboxcolor;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: all 0.2s ease;
  }
  [type='checkbox']:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  [type='checkbox']:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  [type='checkbox']:disabled:checked + label:before,
  [type='checkbox']:disabled:not(:checked) + label:before {
    border-color: $bordercolor;
    background-color: white;
  }
  [type='checkbox']:disabled:checked + label:after {
    background: #ddd;
  }
  li {
    justify-content: flex-start !important;
    margin-bottom: 0.5rem !important;
    label {
      font-size: 0.8rem;
    }
    input {
    }
  }
  #settings {
    justify-content: space-between !important;
    margin-top: 1rem;

    button {
      background: transparent;
      border: none;
      margin-top: 0;
      font-size: 0.8rem;
      text-decoration: underline;
      color: $lightblack;
      width: auto;
      padding: 0;
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}

.plfilters {
  flex-direction: column !important;
  #artwork {
    opacity: 1;
  }
  #filters {
    min-width: 200px;
    margin-right: 0;
    margin-top: 2rem;
    ul .dropdown {
      width: 100%;
    }
    .slider-wrapper {
      width: 92%;
    }
  }
  li {
    flex-direction: column;
    margin-bottom: 1.5rem !important;
    #settings {
      flex-direction: row;
    }
    .cblist {
      width: 100%;
    }
    label {
      margin-bottom: 1rem;
      max-width: none !important;
    }
    form label {
      margin-bottom: 0;
    }
  }
}

.singlepl {
  margin-top: 0;
}
