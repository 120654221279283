@import'variables';


html{
  box-sizing: border-box;
  height: 100vh;
}

body{
  // background: $bg;
  background: $bg;
  font-family: $fontstack;
  margin:0;
  height: 100vh;
}

a:link,a:visited,a:hover,a:active {
    color: $black;
    text-decoration: none;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span{
  font-weight: 300;
  color:$black;
}

h3,h4{
  color:$black
}

img{
  vertical-align: middle;
}

ul{
  margin:0;
  padding:0;
}

li{
  list-style: none;
}

textarea:focus, input:focus{
    outline: none;
}

input{
  box-sizing: border-box;
}

main{
  // height: calc(100vh - 71px);
  // height: 100vh;
  margin-top:71px!important
}


main.login {
  height: 90vh;
  display: flex;
  justify-content: center;
  padding-top: 10vh;
  margin-top: 0 !important;
}

hr{
  margin:3rem 0;
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

p{font-weight: 300}
