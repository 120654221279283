@import 'variables';

/* CARDS */

ul.tracks,
ul.artists,
.tracklist ol,
ul.users,
ul.uploads {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    background: white;
    cursor: pointer;
    transition: $transition;
    box-sizing: border-box;
    border: 1px solid $bordercolor;
    border-bottom: 2px solid $bordercolor;
    &:hover {
      box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.08);
      border-bottom: 2px solid #ccc;
    }
  }
}

ul.tracks {
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;
    height: 80px;
    section,
    a {
      display: flex;
      align-items: center;
    }
    a {
      border-left: 1px solid $bordercolor;
      &:hover .cardinfo {
        display: inline;
      }
      &:hover .gototrack,
      &:hover .addtoplaylist {
        padding-left: 15px;
      }
    }
    .cardinfo {
      box-sizing: border-box;
      font-size: 0.7rem;
      color: $lightblack;
      padding-left: 20px;
      line-height: 75px;
      padding-right: 20px;
      &:hover {
        background: #f8f8f8;
        border-bottom: 3px solid $blue;
        border-top: 1px solid #ddd;
      }
      // display: none;
    }

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    // &:hover .gototrack,&:hover .addtoplaylist{
    //   display: inline;
    // }

    .gototrack,
    .addtoplaylist {
      opacity: 0.3;
      width: 15px;
      margin: 0;
      padding: 25px 30px;
      height: 27px;
      // display: none;
      transition: $transition;
      &:hover {
        opacity: 0.6;
      }
    }
    .gototrack:before {
      content: url(../img/arrow-right.svg);
      display: block;
      line-height: 31px;
    }
    .addtoplaylist:before {
      content: url(../img/plus.svg);
      display: block;
      line-height: 31px;
    }
    .artwork {
      position: relative;
      width: 80px;
      height: 80px;
      margin-right: 1rem;
      background: black;
      img {
        position: absolute;
        opacity: 0.8;
      }

      figure {
        margin: 0;
      }
      .play,
      .pause {
        opacity: 0.9;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        transform: translate(50%, 50%);
        &:hover {
          opacity: 1;
        }
        &:before {
          content: url(../img/play.svg);
        }
      }
      .pause {
        &:before {
          content: url(../img/pause.svg);
        }
      }
    }

    section:nth-child(1) {
      flex: 1;
      min-width: 0;
    }

    section:nth-child(1) span {
      display: block;
      flex-direction: column;
      margin-left: 0;
      flex: 1;
      min-width: 0;
    }

    section:nth-child(2) {
      white-space: nowrap;
    }

    h5,
    h6 {
      margin: 0.4rem;
      margin-left: 0;
      font-size: 0.9rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    h6 {
      font-weight: 300;
      color: $lightblack;
    }
    .cm {
      padding-right: 1rem;
      text-align: right;
      h6 {
        font-size: 0.7rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &:before {
          height: 18px;
          background-size: 18px;
          background-repeat: no-repeat;
          margin-right: 0.5rem;
          display: inline-block;
          opacity: 0.5;
          width: 18px;
          content: '';
        }
        &.genres {
          span {
            margin-right: 4px;
            color: $lightblack;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        &.release-date:before {
          background-image: url(../img/calendar.svg);
        }
        &.genres:before {
          background-image: url(../img/disc.svg);
        }
      }
      .wave-hint {
        height: 50px;
        opacity: 0.12;
        border-left: 1px dashed $bgblack;
        padding-left: 1rem;
        user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        @media (max-width: 1100px) {
          display: none;
        }
      }
    }
    .artists {
      margin: 0.4rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        display: inline-block;
        border: 0;
        font-weight: 300;
        color: $lightblack;
        padding-right: 5px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

ul.artists {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @media only screen and (min-width: 500px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 750px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (min-width: 1300px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media only screen and (min-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @media only screen and (min-width: 1700px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media only screen and (min-width: 2000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media only screen and (min-width: 2500px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media only screen and (min-width: 3000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  li {
    // margin:0 .25rem;
    display: inline-block;
    position: relative;
    overflow: hidden;
    box-sizing: content-box;
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      filter: grayscale(1);
    }
    figure {
      position: relative;
      margin: 0;
    }
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      object-position: 0 0;
    }
    span {
      display: flex;
      flex-direction: column;
      height: 55px;
    }
    h5,
    h6 {
      margin: 0.2rem 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    h5 {
      margin-top: 0.6rem;
    }
    h6 {
      font-weight: 300;
      color: $lightblack;
    }
    .triangle {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 55px;
      margin: 0;
      border-style: solid;
      border-width: 0 0 1rem 20rem;
      border-color: transparent transparent white transparent;
    }
  }
}

ul.users {
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;
    cursor: default;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    p {
      margin-left: 1rem;
      font-size: 0.8rem;
      span {
        color: $lightblack;
      }
    }
    section,
    a {
      display: flex;
      align-items: center;
    }
    .deleteuser,
    .dl {
      border-left: 1px solid $bordercolor;
      padding: 1rem;
    }

    .dl {
      content: url(../img/downloadblack.svg);
    }
  }
}

.deleteuser,
.dl {
  content: url(../img/trashblack.svg);
  opacity: 0.3;
  width: 15px;
  margin: 0;
  transition: $transition;
  &:hover {
    opacity: 1;
  }
}

.tracklist ol {
  margin: 0;
  padding: 0;
  section {
    position: relative;
  }
  section:nth-child(2) {
    white-space: nowrap;
  }
  li {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 0.5rem 0;
    height: 50px;
    width: 100%;
    a {
      border-left: 1px solid $bordercolor;
      display: flex;
      line-height: 50px;
      &:hover .cardinfo {
        display: inline;
      }
      &:hover .gototrack,
      &:hover .addtoplaylist {
        padding-left: 15px;
      }
    }
    .number {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      width: 48px;
      background: $bgblack;
      margin: 0;
      color: white;
      flex-shrink: 0;
    }
    .play,
    .pause {
      opacity: 0.9;
      width: 1.75crem;
      margin: 0;
      background: $bgblack;
      position: absolute;
      top: 23px;
      left: 25px;
      z-index: 9;
      transform: translate(-50%, -50%);
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
      &:before {
        content: url(../img/play-small.svg);
      }
    }
    .pause {
      content: url(../img/pause-small.svg);
    }
    h4 {
      margin: 0 0 0 1rem;
      font-size: 0.8rem;
      align-self: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      time {
        font-weight: 300;
        font-style: italic;
        color: $lightblack;
        padding-left: 0.5rem;
      }
    }

    &:hover .gototrack,
    &:hover .addtoplaylist {
      display: inline;
    }
    .gototrack,
    .addtoplaylist {
      opacity: 0.3;
      width: 15px;
      margin: 0;
      padding: 17px;

      transition: $transition;
      &:hover {
        opacity: 0.6;
      }
    }
    .gototrack:before {
      content: url(../img/arrow-right.svg);
      display: block;
      line-height: 18px;
    }
    .addtoplaylist:before {
      content: url(../img/plus.svg);
      display: block;
      line-height: 18px;
    }
    .cardinfo {
      font-size: 0.7rem;
      color: $lightblack;
      padding: 0 20px;
      height: 44px;
      &:hover {
        background: #f8f8f8;
        border-bottom: 3px solid $blue;
        border-top: 1px solid #ddd;
      }
    }
  }
}

.genre {
  text-align: center;
  span {
    height: 35px !important;
  }
}

.dragdrop {
  width: 3rem;
  height: 65px;
  margin: 0;
  border-right: 1px solid $bordercolor;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: red;
  }
}

.aplay {
  li {
    &:hover,
    &.playing {
      .cover-wp {
        .cover-pl-wp {
          opacity: 1;
        }
      }
    }
    .cover-wp {
      position: relative;
      .cover-pl-wp {
        opacity: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        &:before {
          content: ' ';
          position: absolute;
          background: rgba(0, 0, 0, 0.2);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 1;
        }
        .pause,
        .play {
          cursor: pointer;
        }
        .pause {
          content: url(../img/pause.svg);
        }
        .play {
          content: url(../img/play.svg);
          /* background-image:url(../img/play.svg);
            background-repeat: no-repeat;
            background-position: center center;
            display: inline-block;
            width: 400px;
            height: 400px;
            content:""; */
        }
      }
    }
  }
}
