.upload-heading {
  color: gray;
}

.upload-controls {
  color: #bfbdbd;
  display: flex;
  align-items: flex-end;
  flex-direction: row-reverse;
  margin: 5px 0;
  .upload-control {
    margin: 5px;
    &:hover {
      color: #a3a2a2;
    }
    &:first-child {
      margin-right: 0;
    }
    &:last-child {
    }
  }
}
