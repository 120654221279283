@import'variables';

/* MODAL */

#modal{
  position: absolute;
  top:0;
  bottom:0;
  right:0;
  left:0;
  background:rgba(0,0,0,.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;

  svg{
    position: absolute;
    top:3rem;
    right:3rem;
    height: 3rem;
    width: 3rem;
    cursor: pointer;
  }

  #wrap{
    background: $bg;
    width: 500px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0,0,0,.2);

    header{
      width: 100%;
      padding: 1rem;
      border-bottom:1px solid $bordercolor;
      box-sizing: border-box;
      background: white;
      }

    .content{
      padding: 0 1rem 1rem 1rem;
      max-height: 700px;
      overflow-y: auto;
      }


    #footer{
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      color:white;
      font-weight: 700;
      cursor: pointer;
      }
      .blue{
        background: $blue;
        &:hover{
          background: lighten( $blue, 10% );
        }
      }
      .green{
        background: $green;
        &:hover{
          background: lighten( $green, 10% );
        }
      }
  }
  .userbasic, article{
    margin:0;
  }
  p.submeta{
    text-align: center;
    color:#555;
  }
}
