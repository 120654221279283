@import 'variables';

/* Status message */
#status {
  background: white;
  bottom: -100vh;
  width: 100%;
  transition: $transition;
  box-sizing: border-box;
  z-index: 99;
  iframe {
    display: block;
    border: none;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  #close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    z-index: 100;
    &:hover {
      opacity: 0.8;
    }
  }
}

#status.active {
  top: 0 !important;
}
