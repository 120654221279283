@import 'variables';

$bordercolor-ingestion: #efefef;

/* Ingestion & Related */
.production-item {
  background: white;
  border: 1px solid $bordercolor-ingestion;
  &.open {
    header {
      border-bottom: 1px solid $bordercolor-ingestion;
    }
    .production-item-content {
      display: flex;
    }
  }

  &.production-item-track {
    transition: 0.1s linear;
    header {
      min-height: 40px;
      border-bottom: 0;
    }
    button.btn-gray {
      min-height: 30px;
    }
    &.open {
      transform: scale(1.03);
      margin: 10px 0 20px 0;
      box-shadow: rgba(0, 0, 0, 0.45) 0 15px 25px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      transition: 0.2s linear;
    }
  }

  .items-state-group {
    margin-bottom: 5px;
    padding: 10px;
    &.open {
      h4 {
        margin: 10px 0 20px 0;
      }
    }
    .item-state-heading {
      .btn {
        background: transparent;
        border: 0;
        outline: 0;
        cursor: pointer;
      }
    }
    &:last-child {
      margin: 0;
    }
  }

  .production-item-content {
    padding: 15px;
    display: none;
    flex-direction: column;
    .production-item-controls,
    .production-item-list {
      display: flex;
      flex: 1;
    }
    .production-item-list {
      flex-direction: column;
      a {
        padding-top: 3px;
        text-decoration: underline;
        display: block;
        font-size: 1.5rem;
      }
      span.production-item-list-status {
        margin-top: -3px;
        margin-left: 35px;
        vertical-align: middle;
        display: inline-block;
        align-self: flex-end;
      }
    }
    .production-item-controls {
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 20px;
      .btn {
        margin-left: 10px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  header {
    margin: 0 !important;
    min-height: 60px;
    display: flex;
    align-items: initial !important; // need overwrite as #content header declares align-item:baseline;
    .colp {
      display: flex;
      flex: 1;
      font-size: 0.9rem;
      justify-content: center;
      align-content: center;
      align-items: center;
      border-right: 1px solid $bordercolor-ingestion;
      text-align: center;
      &:last-child {
        border-right: 0;
      }
      &.colp-2x {
        flex: 1.5;
      }
      &.colp-has-btn {
        align-items: inherit;
        .btn {
          flex: 1;
          height: auto;
        }
      }
    }
  }
}

.ingestion {
  .c {
    margin-top: 6rem;
    &:first-child {
      margin: 0;
    }
  }
  p {
    font-size: 1rem;
    line-height: 1.2rem;
    padding-bottom: 1rem;
  }
  .approved {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .multiply {
      width: 100px;
    }
  }
  .status-green {
    margin: 1rem;
    width: 1rem;
    height: 1rem;
    background: $green;
    border-radius: 50%;
    box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
    animation: pulse 2s infinite;
  }
  ul {
    margin-bottom: 1rem;
  }
  h1 {
    margin-top: 5rem;
  }
  h3 {
    font-weight: 300;
  }
  .clientlogo,
  .orlogo {
    position: absolute;
    width: 150px;
    top: calc(2rem + 15px);
    left: 2rem;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .orlogo {
    width: 75px;
    right: 2rem;
    left: initial;
  }
  .clientlogo {
    height: 100px;
  }

  svg {
    margin: 3rem auto;
    width: 150px;
    height: 150px;
    display: block;

    .cls-1 {
      stroke: $green;
      fill: none;
      stroke-width: 3px;
    }
  }

  .circle {
    stroke-dasharray: 700;
    stroke-dashoffset: 700;
  }

  .checkmark {
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
  }

  .run-animation {
    .circle {
      animation: 2.5s circleDraw forwards;
    }

    .checkmark {
      animation: 0.75s checkmarkDraw forwards;
      animation-delay: 1s;
    }
  }
}

.fbc {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 15px;
  background: $gradient;
  margin: 0;
}

.feedback {
  li {
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start !important;
    section,
    form {
      width: 100%;
    }
    p.filename {
      font-weight: 700;
      margin: 0.5rem 0 0 0 !important;
    }
    article {
      margin-bottom: 0;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
    }
    .userbasic {
      margin: 0;
    }
    .note {
      margin-top: 1rem;
    }
    form {
      margin: 0;
    }
  }
}

.sendin {
  margin-top: 2rem;
}

.fcom {
  text-align: center;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(39, 190, 165, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(39, 190, 165, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(39, 190, 165, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(39, 190, 165, 0.4);
    box-shadow: 0 0 0 0 rgba(39, 190, 165, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(39, 190, 165, 0);
    box-shadow: 0 0 0 10px rgba(39, 190, 165, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(39, 190, 165, 0);
    box-shadow: 0 0 0 0 rgba(39, 190, 165, 0);
  }
}

#wupload {
  display: flex;
  justify-content: space-between;
  .upwrap {
    width: 48%;
    .uploadfield {
      border: 1px solid $bordercolor;
      display: flex;
      padding: 4rem;
      justify-content: center;
      align-content: center;
      text-align: center;
      cursor: pointer;
      margin-bottom: 1rem;
      &:hover img {
        opacity: 0.3;
      }
      p {
        font-size: 1rem;
        span {
          font-weight: 700;
        }
      }
      img {
        width: 100px;
        opacity: 0.1;
      }
    }
    li {
      padding: 0 1rem;
      margin-bottom: 1rem;
      span {
        display: flex;
        justify-content: space-between;
        .error,
        .delete {
          text-decoration: underline;
          text-underline-position: under;
        }
        .error {
          color: $red;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
      }
      progress {
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }
}

progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  appearance: none;
  border-radius: 5rem;
  height: 8px;
}

progress[value]::-webkit-progress-bar {
  background-color: $bordercolor;
  border-radius: 5rem;
}

progress[value]::-webkit-progress-value {
  background-image: $gradient;
  border-radius: 5rem;
}

.uploadnotes {
  li {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 0.5rem;
    p {
      font-size: 1rem;
      padding-right: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 20%;
    }
    .userbasic {
      width: 80%;
      input {
        border-left: 1px solid $bordercolor;
        padding: 0 1rem;
      }
    }
  }
}

// Animation

@keyframes circleDraw {
  from {
    stroke-dashoffset: 700;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes checkmarkDraw {
  from {
    stroke-dashoffset: 150;
  }
  to {
    stroke-dashoffset: 0;
  }
}
