@import'variables';

/* Navigation */
nav{
  position: fixed;
  top:0;
  left:0;
  right:0;
  background: white;
  display: flex;
  justify-content: space-between;
  height: 70px;
  border-bottom:2px solid #ccc;
  z-index: 4;


  ul{
    display: flex;
    align-items: center;
    li{
      padding:0 .5rem;
      &.nav-dropdown {
        display: flex;
        align-items: center;
        height: 74px;
        position: relative;
        svg {
          width: 1rem;
          padding-left: .5rem;
        }
        &:hover {
          #menu {
            visibility: visible;
          }
        }
        #menu {
          top: 74px;
          left: 50%;
          margin-left: -4.5rem;
          background: #fff;
          position: absolute;
          z-index: 3;
          display: block;
          visibility: hidden;
          margin-top: -2px;
          background-color: #fff !important;
          li {
            min-width: 9rem;
          }
        }
      }
      a{
        h5{
          padding: .6rem .9rem;
          background: inherit;
        }
        &.active, &:hover {
          h5{
            padding: .6rem .9rem;
            color:white;
            border-radius: $radius;
            background: $gradient;
          }
        }
      }
    }
    li#logomargin{
      margin-right: 3rem;
      overflow: hidden;
      figure{
        position: absolute;
        z-index: 2;
        top:0;
        left:0;
        background: linear-gradient(45deg, #1ed688 0%, #3681e9 100%);
        width: 6rem;
        height: 71px;
        margin:0;
      }
      img{
        position: relative;
        z-index: 3;
      }
    }
  }
   img{
      height: 35px;
      padding: calc(35px/2) 0 1rem 1rem;
    }
    #partnerlogo{
      height:25px;
      padding:25px 0;
    }
    #ftoggle svg,#ctoggle svg{
      width: 1.3rem;
      height: 1.3rem;
    }

    svg{
      fill:$black;
      cursor:pointer;
      &:hover{
        fill:$bgblack;
      }
    }
    & li:hover #user,& li:hover #menu {
      display: block;
    }

    ul#menu{
      left:24rem;
      background: white;
      position: absolute;
      z-index: 3;
      display: none;
      margin-top:-2px;
      background-color: white!important;
      li{
        padding: .3rem 1rem;
        align-content: center;
        border-bottom: 1px solid $bordercolor;
        font-size: .8rem;
        width: 8rem;
        border-right:1px solid $bordercolor;
        border-left:1px solid $bordercolor;
        box-sizing: border-box;
        border-left: 5px solid transparent;
        a{
          display: flex;
          align-items: center;
        }
        &:hover{
          border-left-color: $blue;
        }
      }
    }

    ul#user{
      background: white;
      position: absolute;
      z-index: 3;
      right: 0;
      display: none;
      margin-top:-3px;
      li{
        padding: .3rem 1rem;
        align-content: center;
        border-bottom: 1px solid $bordercolor;
        font-size: .8rem;
        width: 12rem;
        border-right:1px solid $bordercolor;
        border-left:1px solid $bordercolor;
        box-sizing: border-box;
        a{
          display: flex;
          align-items: center;
        }
        &:hover{
          border-left: 5px solid $blue
        }
        svg{
          width: 1rem;
          padding-right: .5rem;
        }
        &:last-child{
          border-bottom: none;
          background-color: $bgblack;
          svg{fill:white}
          p{color:white}
        }
      }
    }
}

#dd{
  display: flex;
  align-items: center;
  height: 74px;
  svg{
    width: 1rem;
    padding-left: .5rem;
  }
  // svg:hover{
  //   fill:#ddd!important;
  // }

}
