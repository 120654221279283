@import 'variables';

form {
  input {
    padding: 1rem;
    font-size: 0.8rem;
    height: 50px;
    width: 100%;
    flex: 1;
    transition: 0.1s all linear;
    border: 1px solid $bordercolor;
    border-left: none;
  }
}

input.warning {
  background: white url(/assets/img/warning.svg) no-repeat;
  background-position: right 1rem center;
  background-size: 1rem;
}
.userbasic {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem 0 -0.5rem;

  // reverse order of the elements to allow background label change when input active
  .inputfield {
    display: flex;
    flex-direction: column;
    .inputfield-group {
      display: flex;
      flex-direction: row-reverse;
    }
    .inputfield-description {
      color: gray;
      font-size: 12px;
      margin: 3px 0 0 0;
    }
    &.vertical {
      .inputfield-group {
        flex-direction: column-reverse;
      }
      input[type='text'],
      input[type='email'] {
        border-left: 1px solid $bordercolor;
        &:disabled {
          + label {
            background: inherit;
          }
        }
        &:focus + label {
          color: inherit;
          background: none;
        }
      }
      label {
        border: 0;
        padding: 0;
        height: auto;
        line-height: 30px;
        float: initial;
      }
    }
    input[type='text'],
    input[type='email'] {
      padding: 1rem;
      font-size: 0.8rem;
      height: 50px;
      width: 100%;
      flex: 1;
      transition: 0.1s all linear;
      border: 1px solid $bordercolor;
      border-left: none;
      float: right;
      &:disabled {
        cursor: not-allowed;
        background: $bgdark;
        + label {
          cursor: not-allowed;
          background: darken($bgdark, 5%);
        }
      }
      // change background of label when focused in input
      &:focus + label {
        color: white;
        background: $lightblack;
      }
    }

    label {
      color: $lightblack;
      font-size: 0.8rem;
      transition: 100ms all linear;
      cursor: text;
      height: 50px;
      background: white;
      line-height: 50px;
      padding: 0 1rem;
      box-sizing: border-box;
      border: 1px solid $bordercolor;
      border-right: none;
      float: left;
    }
  }

  article,
  button {
    flex: 1;
    margin: 0 0.5rem 1rem;
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 30%;
    // flex-basis: 200px;
    // &:first-child{
    //   margin-left:0
    // }
    // &:last-child{
    //   margin-right:0
    // }
    &.active label {
      color: white;
      background: $lightblack;
    }
  }
  label {
    color: $lightblack;
    font-size: 0.8rem;
    transition: 100ms all linear;
    cursor: text;
    height: 50px;
    background: white;
    line-height: 50px;
    padding: 0 1rem;
    box-sizing: border-box;
    border: 1px solid $bordercolor;
    border-right: none;
  }
}

#related {
  input {
    border-radius: 0.3rem 0.3rem 0 0 !important;
  }
  div {
    background: white;
    width: 30rem;
    display: flex;
    margin: 0 auto;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-radius: 0 0 0.3rem 0.3rem;
    box-sizing: border-box;
    padding: 0.7rem;
  }
  img {
    width: 3rem;
    height: 3rem;
  }
  article {
    padding-left: 0.7rem;
    display: flex;
    font-size: 14px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  span {
    text-align: left;
  }

  svg {
    width: 1rem;
    height: 1rem;
    padding-right: 1rem;
    opacity: 0.4;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }

  h3 {
    margin: 0.1rem 0;
  }
  h4 {
    font-weight: 300;
    color: #777;
    margin: 0.1rem 0;
  }
}

// SELECT

.dropdown {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  border: 1px solid #ddd;
  background: white; /* Old browsers */
}

select {
  /* Make sure the select is wider than the container so we can clip the arrow */
  width: 110%;
  max-width: 110%;
  min-width: 110%;
  /* Remove select styling */
  appearance: none;
  -webkit-appearance: none;
  /* Ugly Firefox way of doing it */
  -moz-appearance: window;
  text-indent: 0.01px;
  text-overflow: '';
  /* Magic font size number to prevent iOS text zoom */
  font-size: 0.8rem;
  background: none;
  border: none;
  outline: none;
  /* Padding works surpringly well */
  padding: 1em 22% 1em 1em;
  margin: 4px;
}

/* This hides native dropdown button arrow in IE */
select::-ms-expand {
  display: none;
}

/* Custom arrow - could be an image, SVG, icon font, etc. */
.dropdown:after {
  background: none;
  content: url(../../assets/img/dropdown.svg);
  width: 1rem;
  padding: 0;
  position: absolute;
  right: 1em;
  top: 1em;
  bottom: 0.3em;
  z-index: 1;
  /* This hack makes the select behind the arrow clickable in some browsers */
  pointer-events: none;
}

/* Focus style */
select:focus {
  outline: none;
  // box-shadow: 0 0 3px 3px rgba(180,222,250, .85);
}

/* This hides focus around selected option in FF */
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

#usertype {
  margin-right: 0.5rem;
}

// TOOLTIP

[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 200px;
  border-radius: $radius;
  background-color: black;
  color: white;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 12px;
  line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: ' ';
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}

// Checkbox

.b {
  display: block;
}

.toggle {
  position: relative;
  top: 50%;
  width: 50px;
  height: 30px;
  border-radius: 100px;
  background-color: #ddd;
  // margin: -20px -40px;
  overflow: hidden;
  box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.05);
}

.check {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6;
}

.check:checked ~ .track {
  box-shadow: inset 0 0 0 20px #009688;
}

.check:checked ~ .switch {
  right: 2px;
  left: 22px;
  transition: 0.15s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0.05s, 0s;
}

.switch {
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  right: 22px;
  background-color: #fff;
  border-radius: 36px;
  z-index: 1;
  transition: 0.15s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0s, 0.05s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.track {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
}

// Load more spinner
.spinner {
  margin-top: 2rem;
}
