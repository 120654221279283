@import 'variables';

/* NOTIFICATION */

#nbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 6;
  height: 70px;
  display: table;
  color: white;
  p {
    display: table-cell;
    position: relative;
    font-size: 1rem;
    line-height: 70px;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    span {
      font-weight: 700;
    }
  }
  &.relative {
    position: relative;
  }
  .nbar-icon {
    display: table-cell;
    vertical-align: middle;
  }
}

.blue#nbar {
  background: $blue;
}
.yellow#nbar {
  background: $yellow;
}
.black#nbar {
  background: $black;
}
.red#nbar {
  background: $red;
}
.green#nbar {
  background: $green;
}
