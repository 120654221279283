@import'variables';


// GLOBAL
.slideup{animation: su 300ms both}
.slidedown{animation: sd 300ms both}
.fadein{animation: fi 300ms both}
.fadeout{animation: fo 300ms both}

@keyframes su{from{transform: translate3d(0, 100%, 0);visibility: visible;}to {transform: translate3d(0, 0, 0);}}
@keyframes sd{from {transform: translate3d(0, 0, 0);}to {visibility: hidden;transform: translate3d(0, 100%, 0);}}
@keyframes fi {from {opacity: 0}to {opacity: 1}}
@keyframes fo {from {opacity: 1}to {opacity: 0}}


/* CARDS */
.ar li{
  animation: ar 300ms both
}

.cardinfo{
  animation: ci 300ms both

}

.ar li:nth-child(2){
  animation-delay: 25ms;
}
.ar li:nth-child(3){
  animation-delay: 50ms;
}
.ar li:nth-child(4){
  animation-delay: 75ms;
}
.ar li:nth-child(5){
  animation-delay: 100ms;
}
.ar li:nth-child(6){
  animation-delay: 125ms;
}
.ar li:nth-child(7){
  animation-delay: 150ms;
}
.ar li:nth-child(8){
  animation-delay: 175ms;
}
.ar li:nth-child(9){
  animation-delay: 200ms;
}
.ar li:nth-child(10){
  animation-delay: 225ms;
}
.ar li:nth-child(11){
  animation-delay: 250ms;
}
.ar li:nth-child(12){
  animation-delay: 275ms;
}
.ar li:nth-child(13){
  animation-delay: 300ms;
}
.ar li:nth-child(14){
  animation-delay: 325ms;
}
.ar li:nth-child(15){
  animation-delay: 350ms;
}
.ar li:nth-child(16){
  animation-delay: 375ms;
}
.ar li:nth-child(17){
  animation-delay: 400ms;
}
.ar li:nth-child(18){
  animation-delay: 425ms;
}
.ar li:nth-child(19){
  animation-delay: 450ms;
}
.ar li:nth-child(20){
  animation-delay: 475ms;
}
.ar li:nth-child(21){
  animation-delay: 475ms;
}
.ar li:nth-child(22){
  animation-delay: 500ms;
}
.ar li:nth-child(23){
  animation-delay: 525ms;
}
.ar li:nth-child(24){
  animation-delay: 550ms;
}
.ar li:nth-child(25){
  animation-delay: 575ms;
}
.ar li:nth-child(26){
  animation-delay: 600ms;
}
.ar li:nth-child(27){
  animation-delay: 625ms;
}
.ar li:nth-child(28){
  animation-delay: 650ms;
}
.ar li:nth-child(29){
  animation-delay: 675ms;
}
.ar li:nth-child(30){
  animation-delay: 700ms;
}
.ar li:nth-child(31){
  animation-delay: 725ms;
}
.ar li:nth-child(32){
  animation-delay: 750ms;
}
.ar li:nth-child(33){
  animation-delay: 775ms;
}
.ar li:nth-child(34){
  animation-delay: 800ms;
}
.ar li:nth-child(35){
  animation-delay: 825ms;
}
.ar li:nth-child(36){
  animation-delay: 850ms;
}
.ar li:nth-child(37){
  animation-delay: 875ms;
}
.ar li:nth-child(38){
  animation-delay: 900ms;
}
.ar li:nth-child(39){
  animation-delay: 925ms;
}
.ar li:nth-child(40){
  animation-delay: 950ms;
}

@keyframes ar {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
}

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes ci {
  from {
    opacity: 0;
    -webkit-transform: translate3d(25%, 0, 0);
    transform: translate3d(25%, 0, 0);
}

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* CONTENTHEADER */

#cheader{animation: aimg linear 50s infinite both}
@keyframes aimg {
  0% {
    background-position-y:calc(0% + 20%)
  }

  50% {
    background-position-y:calc(0% + 80%)
  }
  100% {
    background-position-y:calc(0% + 20%)
  }
}


/* AUDIOFEATURES */

.aw figure{animation: aw 2s normal}

@keyframes aw {
  0% {
    width: 0%;
  }
  // 100% {
  //   width: 100%;
  // }
}
