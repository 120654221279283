@import 'variables';

#searched {
  background: $bg;

  #searchgroup {
    margin-top: 0;
    // width: 100vw;
    background-color: $bordercolor;
    padding: 3rem 0;
    border-bottom: 1px solid #ccc;
    // h2{
    //   font-size: 2rem
    // }
  }
}

.trackandartist ul {
  margin-bottom: 15px;
}

#searchgroup {
  text-align: center;
  -webkit-font-smoothing: antialiased;
  padding-top: 20vh;

  // h2{
  //   color:#818181;
  //   font-weight: 300;
  //   font-size: 16px;
  // }

  .trackandartist ul {
    margin-bottom: 15px;
  }

  ul {
    color: #777;
    // margin-bottom: 15px;

    .active {
      font-weight: 700;
      color: $bgblack;
    }

    li {
      display: inline-block;
      padding: 0 0.1rem;
      color: #777;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  #bigsrc {
    background: $bg url('../img/inputsearch.svg') no-repeat 27.3rem;
    background-size: 2rem 2rem;
    width: 30rem;
    border: 1px solid #ccc;
    border-radius: $radius;
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }
}
@media (max-width: 700px) {
  #searchgroup #bigsrc {
    width: calc(100% - 4rem);
    margin: 0 2rem;
  }
}

#logingroup {
  text-align: center;
  width: 20rem;
  -webkit-font-smoothing: antialiased;

  #llogo {
    width: 6rem;
    margin-bottom: 2rem;
  }
  input {
    background-color: white;
    width: 20rem;
    margin: 0 0 0.5rem;
    padding: 1rem 1.5rem;
    font-size: 0.8rem;
    border: 1px solid #ccc;
  }
  p {
    font-size: 1rem;
    color: $lightblack;
    padding: 0.5rem 0;
  }
}

// CONTENT

// main#content{
//   margin-top: 2rem;
//   height: auto;
// }

#content {
  margin: 0 2rem;
  padding: 1rem 0;
  header {
    display: flex;
    align-items: baseline;
    //  justify-content: flex-end;
    //  justify-content:space-between;
    margin: 3rem 0 1rem;

    img {
      margin-right: 0;
      width: 1.5rem;
      opacity: 0.5;
      &:hover {
        opacity: 0.7;
      }
    }
    .inactive {
      cursor: default;
      opacity: 0.15;
      &:hover {
        opacity: 0.15;
      }
    }
  }
}

@media (min-width: 1300px) {
  #content {
    margin: 1rem 2vw;
  }
}
@media (min-width: 1600px) {
  #content {
    margin: 1rem 5vw;
  }
}
@media (min-width: 2000px) {
  #content {
    margin: 1rem 7vw;
  }
}

.toppadding {
  padding-top: 2rem;
}

#noresult {
  text-align: center;
  padding-top: 20vh;
  color: $lightblack;
  font-size: 2rem;
}

// BREADCRUMB

p#breadcrumb {
  -webkit-font-smoothing: antialiased;
  font-size: 0.8rem;
  text-transform: uppercase;
  .active {
    font-weight: 700;
    color: $red;
  }
  a:hover {
    opacity: 0.7;
  }
}

#cheader {
  position: relative;
  display: flex;
  margin-top: 71px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center calc(0% + 20%);
  #color {
    background: linear-gradient(
      to right,
      rgba(242, 79, 55, 0.9) 0%,
      rgba(205, 33, 52, 0.9) 100%
    );
    box-shadow: inset 0px 0px 21px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  h1 {
    color: white;
    z-index: 3;
    font-size: 3rem;
    text-transform: uppercase;
  }
}

#e404 {
  background: url('../img/404.jpg') no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  h5,
  h6,
  p {
    color: white;
    padding: 0.3rem;
  }
  h5 {
    font-size: 2rem;
  }
  h6 {
    font-size: 1.5rem;
    margin: 0 3rem;
  }
  p {
    margin: 0 3rem;
  }
}

#emptystate {
  width: 100%;
  margin: 20vh 0 0 0;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $bordercolor;

  svg {
    height: 7rem;
    width: 7rem;
    fill: $bordercolor;
  }
}

.contentpush {
  margin-top: 8rem !important;
}

.betainfo {
  background: $bordercolor;
  padding: 1rem;
  border-radius: $radius;
  font-size: 0.8rem;
  line-height: 1.3rem;
  p,
  i,
  h4 {
    color: $lightblack;
  }
  p {
    margin: 0;
  }
  h4 {
    margin: 0 0 0.5rem 0;
  }
  span {
    display: flex;
    justify-content: space-between;
    img {
      width: 12px;
      height: 12px;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.artistpage {
  margin-top: 1rem !important;
}
#arhead {
  height: 450px;
  background: black;
  overflow: hidden;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  figure {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    background-position: 25% center;
    filter: blur(10px);
    transform: scale(1.2);
    opacity: 0.8;
    &.empty {
      background: $gradient !important;
    }
  }
  .c {
    position: absolute;
    text-align: center;
    padding: 0 5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    h1 {
      font-size: 4rem;
    }
    h3 {
      display: block;
      color: white;
      font-size: inherit;
      margin-bottom: 5px;
    }
    ul.c-artists {
      li {
        display: inline-block;
        padding: 0 5px;
        &:after {
          margin-left: 10px;
          content: '|';
        }
        &:last-child {
          &:after {
            content: '';
          }
        }
        a {
          color: white;
          line-height: 1.5rem;
          &:hover {
            text-decoration: underline;
            text-underline-position: under;
          }
        }
      }
    }
  }
}

.vert {
  flex-direction: column;
  p {
    margin-top: 0;
  }
}
