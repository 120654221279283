// FONTS
// $fontstack:'Roboto', sans-serif;
$fontstack:-apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif;



// COLORS
$bg:#f5f5f5;
$bgdark: darken($bg, 3%);

$black:#151515;
$bgblack:#26272b;
$lightblack:#777;

$green:#27bea5;
$darkgreen:#2dadb7;

$red:#cd2134;

$yellow:#ffa800;

$blue:#3684e6;

$bgcart:#374750;

$checkboxcolor: #009688;


$bordercolor:#ddd;

$gradient:linear-gradient(45deg, $green 0%, $blue 100%);

// BORDER
$radius: .3rem;

// TRANSITION
$transition: all 300ms ease;

// PADDING
$padding:1rem;
